import { useQuery, useMutation } from '@apollo/client';
import {
  Button,
  Space,
} from '@mantine/core';
import { useForm } from 'react-hook-form';
import { notifications } from '@mantine/notifications';

import {
  GET_ALL_SHADES,
  CREATE_ANATOMY_ITEM,
  UPDATE_ANATOMY_ITEM,
  GET_ALL_SCAN_POSTS,
} from '../../../gql/';

import {
  ItemType,
  SelectInputRef,
  TextInputRef,
  STYLE,
  TOGGLE_FEATURE,
  ANATOMY_ITEM_CHOICE_DATA,
  getAnatomyItemChoiceWhere,
  ShadeSelection,
  deduceShadeType,
} from '@jasper/shared';

const CreateAnatomyItemComponent = ({ closeModal, productId, refetch, item, redirect, accountProductPreferences, order }) => {

  const rhf = useForm({
    defaultValues: {
      itemType: item?.itemType,
      material: item?.itemMaterial?.id ?? "",
      shade: item?.itemShade?.id ?? "",
      teeth: item?.teeth?.join(','),
      inlayCoreMaterial: item?.inlayCoreMaterial?.id,
      inlayCoreScanPost: item?.inlayCoreScanPost,
      shadeType: deduceShadeType(item?.itemShade?.id ?? item?.teethShade?.id, order?.user?.id, accountProductPreferences),
    }
  });

  const watchItemType = rhf.watch("itemType");
  const watchMaterial = rhf.watch("material");
  const watchShadeType = rhf.watch("shadeType");

  const { data: allMaterials } = useQuery(
    ANATOMY_ITEM_CHOICE_DATA,
    {
      variables: {
        where: getAnatomyItemChoiceWhere(watchShadeType, item?.itemType)
      },
    }
  );
  const { data: allScanPosts } = useQuery(GET_ALL_SCAN_POSTS);

  const [createAnatomyItem] = useMutation(CREATE_ANATOMY_ITEM);
  const [updateAnatomyItem] = useMutation(UPDATE_ANATOMY_ITEM);

  const isNewWorkflow = (): boolean => {
    if (!TOGGLE_FEATURE.NEW_WORKFLOW){
      return (false);
    }
    if (
      watchItemType !== ItemType.INLAY_CORE
      && watchItemType !== ItemType.LAY
      && watchItemType !== ItemType.ONLAY
      && watchItemType !== ItemType.INLAY
      && watchItemType !== ItemType.OVERLAY
      && watchItemType !== ItemType.CROWN
    ){
      return (false);
    }
    return (true);
  }

  const getAllMaterials = () => {
    return (allMaterials?.getItemMaterialsWhere ?? []).map((material) => ({value: material.id, label: material.label}));
  };

  const submit = async (data) => {
    if (isNewWorkflow()){
      redirect(data.itemType); 
    } else {
      try {
        if (item?.id){
          await updateAnatomyItem({
            variables: {
              data: {
                itemMaterial: data.material
                ? {
                  connect: {
                    id: data.material,
                  }
                } : undefined,
                itemShade: data.shade
                ? {
                  connect: {
                    id: data.shade,
                  }
                } : undefined,
                itemType: {
                  set: data.itemType
                },
                teeth: data.teeth?.split(',').map((t) => parseInt(t)),
                inlayCoreMaterial: data.inlayCoreMaterial
                ? {
                  connect: {
                    id: data.inlayCoreMaterial,  
                  }
                } : undefined,
                inlayCoreScanPost: data.inlayCoreScanPost
                ? {
                  set: data.inlayCoreScanPost
                } : undefined,
              },
              where: {
                id: item.id,
              }
            }
          });
          notifications.show({
            title: "Anatomy item updated",
            color: "green",
            message: "",
          });
        } else {
          await createAnatomyItem({
            variables: {
              args: {
                teeth: data.teeth?.split(',').map((t) => parseInt(t)),
                itemType: data.itemType,
                itemMaterial: data.material
                ? {
                  connect: {
                    id: data.material
                  }
                } : undefined,
                itemShade: data.shade
                ? {
                  connect: {
                    id: data.shade
                  }
                } : undefined,
                product: {
                  connect: {
                    id: productId
                  }
                },
                inlayCoreMaterial: data.inlayCoreMaterial
                ? {
                  connect: {
                    id: data.inlayCoreMaterial,  
                  }
                } : undefined,
                inlayCoreScanPost: data.inlayCoreScanPost
                ? {
                  set: data.inlayCoreScanPost
                } : undefined,
              }
            }
          });
          notifications.show({
            title: "Anatomy item created",
            color: "green",
            message: "",
          });
        }
        refetch();
      } catch (e) {
        notifications.show({
          title: "Error while trying to create or update anatomy item",
          color: "red",
          message: "",
        });
      }
      closeModal();
    }
  };

  return (
    <>
      <SelectInputRef
        name="itemType"
        data={Object.keys(ItemType)}
        label="Item Type *"
        errors={rhf.formState.errors}
        control={rhf.control}
      />
      {!isNewWorkflow() &&
        <>
          <Space h="md"/>
          <SelectInputRef
            name="material"
            data={getAllMaterials()}
            label="Material"
            errors={rhf.formState.errors}
            control={rhf.control}
            required={false}
          />
          <Space h="md"/>
          {watchMaterial !== "METAL_NON_PRECIOUS" &&
            <ShadeSelection
              watchShadeType={watchShadeType}
              control={rhf.control}
              errors={rhf.formState.errors}
              anatomyItemChoicesData={allMaterials ?? []}
              onSelect={(selectedValue) => rhf.setValue("shadeType", selectedValue)}
              order={order}
              accountProductPreferences={accountProductPreferences}
              rhf={rhf}
              itemType={watchItemType}
            />
          }
          <Space h="md"/>
          <TextInputRef
            name="teeth"
            label="Teeth (e.g 4,5)"
            errors={rhf.formState.errors}
            control={rhf.control}
            required={false}
          />
          {watchItemType === ItemType.INLAY_CORE &&
            <>
              <Space h="md"/>
              <SelectInputRef
                name="inlayCoreMaterial"
                data={getAllMaterials()}
                label="InlayCore Material"
                errors={rhf.formState.errors}
                control={rhf.control}
                required={false}
              />
              <Space h="md"/>
              <SelectInputRef
                name="inlayCoreScanPost"
                data={(allScanPosts?.getScanPostsData?.scanPosts ?? [])}
                label="InlayCore ScanPost" 
                errors={rhf.formState.errors}
                control={rhf.control}
                required={false}
              />
            </>
          }
        </>
      }
      <Space h="md"/>
      <div style={{ textAlign: 'center' }}>
        <Button style={{ backgroundColor: STYLE.primary }} onClick={rhf.handleSubmit(submit)}>
          Submit
        </Button>
      </div>
    </>
  );
};

export default CreateAnatomyItemComponent;
