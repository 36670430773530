import React from "react";
import {
  Title,
  Text,
  Space,
  Button,
  Group,
} from "@mantine/core";
import {
  useForm,
} from "react-hook-form";

import SelectorButtonRef from "../../SelectorButtonRef";
import TextInputRef from "../../TextInputRef";
import {
  AbutmentType,
  RetentionType,
} from "../../../types/enums";
import {
  AbutmentTypeChoices,
  StandardAbutmentTypeChoices,
} from "../../../types/constants";

const CreateNewImplantAbutmentInfo = ({ onSubmit, previousStep, savedItem }) => {
  const {
    control,
    watch,
    handleSubmit,
    formState: {errors},
    setValue,
  } = useForm({
    defaultValues: {
      abutmentType: savedItem?.abutmentType === AbutmentType.STANDARD_GENERIC
          ? AbutmentType.STANDARD
          : savedItem?.abutmentType ?? undefined,
      standardAbutmentType: savedItem?.abutmentType ?? undefined,
      standardAbutmentSuggestion: savedItem?.standardAbutmentSuggestion,
    }
  });

  const watchAbutmentType = watch("abutmentType");
  const watchStandardAbutmentType = watch("standardAbutmentType");

  return (
    <>
      <Title order={2}>Pilier implantaire</Title>
      <Text
        fs="italic"
        size="sm"
      >
        Un pilier personnalisé est usiné sur mesure et adapté au mieux au cas de votre patient, grâce aux librairies de CFAO de votre fabricant d'implant. Le délai de fabrication d'un pilier personnalisé est plus court que celui d'un pilier standard.
      </Text>
      <Space h="sm"/>
      <SelectorButtonRef
        label="Type de pilier"
        name="abutmentType"
        data={AbutmentTypeChoices}
        onSubmit={(selectedValue) => setValue("abutmentType", selectedValue)}
        control={control}
        errors={errors}
        required={true}
        watch={watchAbutmentType}
      />
      {(watchAbutmentType &&
        (watchAbutmentType === AbutmentType.STANDARD || watchAbutmentType === AbutmentType.STANDARD_GENERIC)
      ) && (
        <>
          <SelectorButtonRef
            label="Type de pilier standard"
            name="standardAbutmentType"
            data={StandardAbutmentTypeChoices}
            onSubmit={(selectedValue) => setValue("standardAbutmentType", selectedValue)}
            control={control}
            errors={errors}
            required={true}
            watch={watchStandardAbutmentType}
          />
          <TextInputRef
            label="Préférence de pilier standard"
            name="standardAbutmentSuggestion"
            control={control}
            errors={errors}
            required={true}
          />
        </>
      )}
      <Group justify="space-between" mt="xl">
        <Button variant="default" onClick={previousStep}>Previous step</Button>
        <Button onClick={handleSubmit(onSubmit)}>Next step</Button>
      </Group>
    </>
  )
};

export default CreateNewImplantAbutmentInfo;
