import React from "react";
import {
  Title,
  Box,
  Text,
} from "@mantine/core";
import {
  SelectorButtonRef,
} from "@jasper/shared";
import {
  WorkflowType,
} from "../../../../types/enums";

const workflowTypeChoices = [
  {
    value: WorkflowType.ONE_APPOINTMENT_DIRECT,
    label: (
      <Box
        style={theme => ({
          display: "flex",
          flexDirection: "column",
          margin: theme.spacing.sm,
        })}
      >
        <Title order={6}>Finition Directe: 1 RDV</Title>
        <Text size="sm">
          A privilégier en cas d'occlusion nette et de moins de 5 dents manquantes.
        </Text>
      </Box>
    ),
  },
  {
    value: WorkflowType.WITH_FITTING,
    label: (
      <Box
        style={theme => ({
          display: "flex",
          flexDirection: "column",
          margin: theme.spacing.sm,
        })}
      >
        <Title order={6}>
          {"Essayage avec mise en place des dents: 2 RDVs"}
        </Title>
        <Text size="sm">
          A privilégier s'il manque 3 dents antérieures ou plus. Aide à visualiser l'esthetique et l'occlusion.
        </Text>
      </Box>
    ),
  },
  {
    value: WorkflowType.WAX_RIM,
    label: (
      <Box
        style={theme => ({
          display: "flex",
          flexDirection: "column",
          margin: theme.spacing.sm,
        })}
      >
        <Title order={6}>{"Cire d'occlusion: 2 RDVs"}</Title>
        <Text size="sm">
          A privilégier lorsqu'il reste quelques dents au patient. Permet d'établir la relation maxillo mandibulaire.
        </Text>
      </Box>
    ),
  },
];

const PartialDentureFlux = ({
  rhf,
}) => {
  const watchWorkflowType = rhf.watch("workflowType");

  return (
    <>
      <Title order={2}>Flux de travail</Title>
      <SelectorButtonRef
        label="Comment souhaitez-vous procéder ?"
        name="workflowType"
        data={workflowTypeChoices}
        onSubmit={(selectedValue) => rhf.setValue("workflowType", selectedValue)}
        control={rhf.control}
        errors={rhf.formState.errors}
        required={true}
        watch={watchWorkflowType}
      />
    </>
  )
};

export default PartialDentureFlux;
