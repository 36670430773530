import { gql } from '@apollo/client';

export const GET_LAST_UNREAD_HELP_COMMENT_OF_EACH_ORDER = gql`
  query GetHelpMsgSliceByOrder ($args: HelpMessageInput!) {
    getHelpMsgSliceByOrder (args: $args) {
      notification {
        id
        createdAt
        order {
          orderReference
          id
          status
          patient {
            firstName
            lastName
          }
        }
        files {
          key
        }
        comment
        read
      }
      count
    }
  }
`;

export const GET_NUMBER_OF_NOTIFICATIONS = gql`
  query GetNotificationCount {
    getNotificationCount {
      helpNotification
      chatNotification
    }
  }
`;

export const UPDATE_HELP_MESSAGES = gql`
  mutation UpdateHelpMessages($data: OrderCommentUpdateManyMutationInput!, $where: OrderCommentWhereInput!) {
    updateHelpMessages(data: $data, where: $where)
  }
`;

export const SET_LAST_CHAT_OF_ORDER_AS_UNREAD = gql`
  mutation MarkLastMessageAsUnread($orderId: String!) {
    markLastMessageAsUnread(orderId: $orderId)
  }
`;
