import React, {
    useEffect,
  useState,
} from "react";
import {
  Stepper,
} from "@mantine/core";

import CreateNewImplantImplantInfo from "./createNewImplantImplantInfo";
import CreateNewImplantAbutmentInfo from "./createNewImplantAbutmentInfo";
import CreateNewImplantCrownInfo from "./createNewImplantCrownInfo";
import PreferenceSelection from "../preferenceSelection";
import { ItemType, AbutmentType, TeethShadeType, TeethShadeSide } from "../../../types/enums";
import { useLocation } from "react-router-dom";
import { getAllNonSplintedCrown, getAllPossibleSplintedCrown } from "../../../utils/product.utils";
import {
  getFixedProsthesisPreferences,
} from "../../../utils/item.utils";

interface CreateNewImplantCrownProps {
  materialFormChoices;
  anatomyItemChoicesData;
  accountProductPreferences;
  order;
  abutmentMaterials;
  implantItemChoicesData;
  updateImplantItem;
  createImplantItem;
  product_id: string;
  updateProduct;
  getUserProductPreferences;
  onValidate;
  deleteManyImplantItem;
};

const CreateNewImplantCrown = (props: CreateNewImplantCrownProps) => {

  const [active, setActive] = useState(0);
  const [newImplantItemId, setNewImplantItemId] = useState<string | null>(null);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const [itemPreferences, setItemPreferences] = useState(null);
  const location = useLocation();
  const savedItem = (location?.state?.product?.implantItem ?? []).find((item) => item.itemType === ItemType.IMPLANT);
  const savedImplantCrownItem = (location?.state?.product?.implantItem ?? []).find((item) => item.itemType === ItemType.IMPLANT_CROWN || item.itemType === ItemType.SPLINTED_CROWN_IMPLANT);
  const product = location?.state?.product;

  useEffect(() => {
    const loadItemPreferences = async() => {
      const userProductPreferences = await props.getUserProductPreferences();
      setItemPreferences(
        getFixedProsthesisPreferences(
          product.productType,
          userProductPreferences,
        )
      );
    }
    loadItemPreferences();
  }, []);

  return (
    <>
      <Stepper active={active} onStepClick={setActive}>
        <Stepper.Step label="Implant">
          <CreateNewImplantImplantInfo
            implantItemChoicesData={props.implantItemChoicesData}
            savedItem={savedItem}
            onSubmit={async(data, isImplantReferenceNotFound) => {
              if (savedItem?.id || newImplantItemId){
                await props.updateImplantItem({
                  variables: {
                    where: {
                      id: savedItem?.id ?? newImplantItemId,
                    },
                    data: {
                      implantReference: (!isImplantReferenceNotFound && data.implantReferenceId)
                        ? {
                          connect: {
                            id: data.implantReferenceId,
                          }
                        }
                        : {
                          disconnect: {
                            id: {
                              equals: data.implantReferenceId
                            },
                          }
                        },
                      implantPlatform: {
                        set: (!isImplantReferenceNotFound && data.platform) ? data.platform : null,
                      },
                      implantReferenceSuggestion:
                        data.implantReferenceSuggestion &&
                        data.implantReferenceSuggestion !== "" &&
                        isImplantReferenceNotFound
                          ? { set: `${data.implantBrandSuggestion ?? ""} - ${data.implantReferenceSuggestion} - ${data.implantPlatformSuggestion}`}
                          : null,
                      scanBodySuggestion: data.scanBodySuggestion
                      ? {
                        set: data.scanBodySuggestion
                      } : undefined,
                    },
                  }
                })
              } else {
                const req = await props.createImplantItem({
                  variables: {
                    args: {
                      implantReference: data.implantReferenceId
                        ? {
                          connect: {
                            id: data.implantReferenceId,
                          }
                        }
                        : undefined,
                      itemType: ItemType.IMPLANT,
                      implantPlatform: data.platform,
                      scanBodySuggestion: data.scanBodySuggestion,
                      product: {
                        connect: {
                          id: props.product_id
                        },
                      },
                      teeth: product?.teeth,
                      implantReferenceSuggestion:
                        data.implantReferenceSuggestion &&
                        data.implantReferenceSuggestion !== "" &&
                        isImplantReferenceNotFound
                          ? `${data.implantBrandSuggestion ?? ""} - ${data.implantReferenceSuggestion} - ${data.implantPlatformSuggestion}`
                          : undefined,
                    }
                  }
                });
                setNewImplantItemId(req?.data?.createImplantItem?.id)
              }
              nextStep();
            }}
          />
        </Stepper.Step>
        <Stepper.Step label="Pilier">
          <CreateNewImplantAbutmentInfo
            onSubmit={async(data) => {
              await props.updateImplantItem({
                variables: {
                  where: {
                    id: savedItem?.id ?? newImplantItemId
                  },
                  data: {
                    abutmentType: (data.standardAbutmentType && data.abutmentType === AbutmentType.STANDARD)
                      ? { set: data.standardAbutmentType }
                      : data.abutmentType
                        ? { set: data.abutmentType }
                        : undefined,
                  abutmentMaterial: data.abutmentMaterial
                    ? {
                      connect: {
                        id: data.abutmentMaterial
                      }
                    }
                    : undefined,
                  standardAbutmentSuggestion: (data.standardAbutmentSuggestion && data.standardAbutmentSuggestion !== "")
                      ? { set: data.standardAbutmentSuggestion }
                      : undefined,
                  },
                }
              });
              nextStep();
            }}
            previousStep={() => prevStep()}
            savedItem={savedItem}
          />
        </Stepper.Step>
        <Stepper.Step label="Couronne">
          <CreateNewImplantCrownInfo
            materialFormChoices={props.materialFormChoices}
            anatomyItemChoicesData={props.anatomyItemChoicesData}
            accountProductPreferences={props.accountProductPreferences}
            order={props.order}
            previousStep={() => prevStep()}
            itemType={ItemType.IMPLANT}
            product={product}
            savedItem={savedImplantCrownItem}
            onSubmit={async(data) => {
              const isMultiShade = data.isMultiShade;
              await props.deleteManyImplantItem({
                variables: {
                  where: {
                    productId: {
                      equals: product.id,
                    },
                    itemType: {
                      in: [
                        ItemType.IMPLANT_CROWN,
                        ItemType.SPLINTED_CROWN_IMPLANT,
                        ItemType.BRIDGE_IMPLANT,
                      ],
                    },
                  },
                },
              });
              const allNonPossibleSplintedCrown: number[] = getAllNonSplintedCrown(
                product.teeth,
              );
              const allSplinteCrown: number[][] = getAllPossibleSplintedCrown(
                product.teeth,
              );
              const productOfOrder = [];
              if (data.isSplintedCrown && allSplinteCrown.length > 0) {
                allSplinteCrown.map(splintedCrown => {
                  productOfOrder.push({
                    itemMaterial: {
                      connect: {
                        id: data.material,
                      },
                    },
                    itemShade:
                      data.shade && !isMultiShade
                        ? {
                            connect: {
                              id: data.shade,
                            },
                          }
                        : undefined,
                    teeth: splintedCrown,
                    itemType: ItemType.SPLINTED_CROWN_IMPLANT,
                    product: {
                      connect: {
                        id: product.id,
                      },
                    },
                    retentionType: data.retentionType,
                    teethshadeType:
                      isMultiShade
                        ? TeethShadeType.MULTI_SHADE
                        : TeethShadeType.SINGLE_SHADE,
                    multiShadeInfo:
                      isMultiShade
                        ? {
                            createMany: {
                              data: [
                                ...(data.gingivalShade
                                  ? [
                                      {
                                        itemShadeId: data.gingivalShade,
                                        teethShadeSide: TeethShadeSide.GINGIVAL,
                                      },
                                    ]
                                  : []),
                                ...(data.baseShade
                                  ? [
                                      {
                                        itemShadeId: data.baseShade,
                                        teethShadeSide: TeethShadeSide.BASE,
                                      },
                                    ]
                                  : []),
                                ...(data.incisalShade
                                  ? [
                                      {
                                        itemShadeId: data.incisalShade,
                                        teethShadeSide: TeethShadeSide.INCISAL,
                                      },
                                    ]
                                  : []),
                              ],
                            },
                          }
                        : undefined,
                  });
                });
              }
              if (
                !data.isSplintedCrown ||
                allNonPossibleSplintedCrown.length > 0
              ) {
                productOfOrder.push({
                  itemMaterial: {
                    connect: {
                      id: data.material,
                    },
                  },
                  itemShade:
                    data.shade && !isMultiShade
                      ? {
                          connect: {
                            id: data.shade,
                          },
                        }
                      : undefined,
                  teeth:
                    data.isSplintedCrown
                      ? allNonPossibleSplintedCrown
                      : product.teeth,
                  itemType: ItemType.IMPLANT_CROWN,
                  product: {
                    connect: {
                      id: product.id,
                    },
                  },
                  retentionType: data.retentionType,
                  teethshadeType:
                    isMultiShade
                      ? TeethShadeType.MULTI_SHADE
                      : TeethShadeType.SINGLE_SHADE,
                  multiShadeInfo:
                    isMultiShade
                      ? {
                          createMany: {
                            data: [
                              ...(data.gingivalShade
                                ? [
                                    {
                                      itemShadeId: data.gingivalShade,
                                      teethShadeSide: TeethShadeSide.GINGIVAL,
                                    },
                                  ]
                                : []),
                              ...(data.baseShade
                                ? [
                                    {
                                      itemShadeId: data.baseShade,
                                      teethShadeSide: TeethShadeSide.BASE,
                                    },
                                  ]
                                : []),
                              ...(data.incisalShade
                                ? [
                                    {
                                      itemShadeId: data.incisalShade,
                                      teethShadeSide: TeethShadeSide.INCISAL,
                                    },
                                  ]
                                : []),
                            ],
                          },
                        }
                      : undefined,
                });
              }
              await Promise.all(
                productOfOrder.map(async(item) => {
                  await props.createImplantItem({
                    variables: {
                      args: item,
                    },
                  });
                })
              )
              nextStep();
            }}
          />
        </Stepper.Step>
        <Stepper.Step label="Preferences">
          <PreferenceSelection
            productType={ItemType.IMPLANT}
            previousStep={() => prevStep()}
            itemPreferences={itemPreferences}
            onSubmit={async(data) => {
              await props.updateProduct({
                variables: {
                  where: {
                    id: product?.id,
                  },
                  data: {
                    itemPreferences: {
                      set: {...itemPreferences, ...data},
                    },
                  },
                }
              })
              props.onValidate();
            }}
          />
        </Stepper.Step>
        <Stepper.Completed>
          Completed, click back button to get to previous step
        </Stepper.Completed>
      </Stepper>
    </>
  )
};

export default CreateNewImplantCrown;
