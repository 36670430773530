import React, {
  useState,
  useEffect,
} from "react";

import SelectorButtonRef from "./SelectorButtonRef";
import SelectInputRef from "./SelectInputRef";
import {
  filterShadeByShadeType,
  getShadeTypeAvailableByItemType,
} from "../utils/item.utils";
import {
  findUserPreference,
} from "../utils/product.utils";
import {
  ItemType,
} from "../types/enums";
import { YES_OR_NO_CHOICE } from "../types/constants";
import MultiShadeForm from "./MultiShadeForm";
import { GetItemShadesWhere } from "../types/interfaces";

const ShadeSelection = ({
  watchShadeType,
  control,
  errors,
  anatomyItemChoicesData,
  onSelect,
  accountProductPreferences,
  user,
  itemType = undefined,
  order = null,
  isRemovableItem = false,
  user_id = "",
  rhf,
}) => {

  const [shadeFormChoices, setShadeFormChoice] = useState<GetItemShadesWhere[]>([]);

  const watchIsMultiShade = rhf.watch("isMultiShade");
  const watchBaseShade = rhf.watch("baseShade");
  const watchIncisalShade = rhf.watch("incisalShade");
  const watchGingivalShade = rhf.watch("gingivalShade");

  useEffect(() => {
    setShadeFormChoice(filterShadeByShadeType(anatomyItemChoicesData?.getItemShadesWhere, watchShadeType));
  }, [watchShadeType, (anatomyItemChoicesData?.getItemShadesWhere ?? []).length]);

  useEffect(() => {
    const shadeType = findUserPreference(order?.user?.id ?? user_id, accountProductPreferences)?.shadeType;
    if (shadeType && shadeType !== watchShadeType){
      onSelect(shadeType);
    }
    setShadeFormChoice(filterShadeByShadeType(anatomyItemChoicesData?.getItemShadesWhere, watchShadeType));
  }, [accountProductPreferences]);

  return (
    <>
      <SelectorButtonRef
        label="Type de teintier"
        name="shadeType"
        data={getShadeTypeAvailableByItemType(isRemovableItem, itemType).map((shadeType) => ({ value: shadeType, label: shadeType.replaceAll("_", " ") }))}
        onSubmit={(selectedValue) => onSelect(selectedValue)}
        control={control}
        errors={errors}
        watch={watchShadeType}
        required={true}
      />
      {(
        (
          itemType === ItemType.CROWN ||
          itemType === ItemType.INLAY_CORE ||
          itemType === ItemType.BRIDGE ||
          itemType === ItemType.IMPLANT ||
          itemType === ItemType.BRIDGE_IMPLANT
        ) && user?.isMultiShadeEnabled
      ) && (
        <SelectorButtonRef
          label="Affiner l'esthétique avec un dégradé de teinte ?"
          name="isMultiShade"
          data={YES_OR_NO_CHOICE}
          onSubmit={(selectedValue) => rhf.setValue("isMultiShade", selectedValue)}
          control={control}
          errors={errors}
          watch={watchIsMultiShade}
          required={true}
        />
      )}
      {watchIsMultiShade ? (
        <MultiShadeForm
          control={control}
          shadeFormChoices={shadeFormChoices}
          errors={errors}
          watchBaseShade={watchBaseShade}
          watchIncisalShade={watchIncisalShade}
          watchGingivalShade={watchGingivalShade}
          watchIsMultiShade={watchIsMultiShade}
        />
      ) : (
        <SelectInputRef
          label="Teinte"
          name="shade"
          data={shadeFormChoices}
          control={control}
          errors={errors}
          required={true}
        />
      )}
    </>
  );
};

export default ShadeSelection;
