import { ReactNode, useEffect, useState } from 'react';
import { useQuery } from '@apollo/client';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Collapse, Group, Text } from '@mantine/core';
import {
  GET_NUMBER_OF_NOTIFICATIONS,
  COUNT_NUMBER_OF_NEW_CASES_FOR_STAFF,
} from '/@/gql/';
import { socket } from '../socket'

import { UseAuth } from '/@/contexts';
import Logo from "/@/assets/logo.svg";
import {
  STYLE,
  OrderStatus,
  isUserProvider,
  isUserStaff,
  isUserAdmin,
} from '@jasper/shared';

import packageJson from "../../package.json";
import { useDisclosure } from '@mantine/hooks';
import { 
  IconSquareRoundedArrowUpFilled,
  IconSquareRoundedArrowDownFilled,
  IconMessageCircleSearch,
  IconAlertTriangle,
  IconMenu2,
  IconLogout,
  IconUsers,
  IconUsersGroup,
  IconBell
} from '@tabler/icons-react'

const NavLink = (props: {
  title: string,
  onClick: () => any,
  active?: boolean,
  notificationNumber?:number,
  icon?: ReactNode
}) => {
  return (
    <div
      style={{
        cursor: "pointer",
        color: props.active ? STYLE.primary : "#050027",
        fontWeight: 650,
        textAlign: "left",
        paddingBottom: "0.5rem",
        paddingLeft: '20px'
      }}
      onClick={props.onClick}
    >
      {props.icon && props.icon} 
      {props.title}
      {props.notificationNumber && 
        <span 
          style={{
            position: 'absolute',
            width:'20px',
            height:'20px',
            borderRadius: '50px',
            backgroundColor:'#F24034',
            marginLeft: '10px',
            fontSize: 'x-small',
            textAlign: 'center',
            lineHeight: '20px',
            color: 'white'
          }}
        >
          { props.notificationNumber}
        </span>
      }
    </div>
  );
};

const Navbar = () => {
  const { logOut, user } = UseAuth();
  const navigate = useNavigate();
  const [opened, { toggle }] = useDisclosure(false);
  const [openedNotification, { toggle : toggleNotification}] = useDisclosure(false);

  const [searchParams] = useSearchParams();
  const [chatNotification, setChatNotification] = useState<number>(0);
  const [helpNotification, setHelpNotification] = useState<number>(0);
  const [newCases, setNewCases] = useState<number>(0);

  const { data: chatAndHelpNotificationCount } = useQuery(
    GET_NUMBER_OF_NOTIFICATIONS,
  );

  const { data: numberOfNewCases } = useQuery(
    COUNT_NUMBER_OF_NEW_CASES_FOR_STAFF,
    {
      variables: {
        where: {
          status: {
            equals: isUserProvider(user) ? OrderStatus.WAITING_FOR_PRODUCTION : OrderStatus.CONFIRMED
          },
          isArchived: {
            equals: false
          },
        },
      },
      fetchPolicy: "network-only",
    },
  );

  const logOutFromLabs = async() => {
    await logOut();
    navigate('/');
  }

  const nextUrl = (urlToNavigate: string) => {
    navigate(urlToNavigate);
  }

  useEffect(() => {
    if(isUserProvider(user)) {
      socket.emit('joinRoom', {room: 'provider-pastilles-'+user.userGroupId})
  
      socket.on('provider-pastilles-'+user.userGroupId, (value: {type: string, action: string, userGroupId: string}) => {
        if(value.type === 'ChatNotification') {
          if(value.action === 'add') {
            setChatNotification(val => val + 1)
          }
          else if(value.action === 'remove') {
            setChatNotification(val => val - 1)
          }
        }
        else if(value.type === 'NewOrderNotification') {
          if(value.action === 'add') {
            setNewCases(val => val + 1)
          }
          else if(value.action === 'remove') {
            setNewCases(val => val - 1)
          }
        }
      })
    }
    else {
      socket.emit('joinRoom', {room: 'admin-pastilles'})

    socket.on('admin-pastilles', (value: {type: string, action: string}) => {
      if(value.type === 'ChatNotification') {
        if(value.action === 'add') {
          setChatNotification(val => val + 1)
        }
        else if(value.action === 'remove') {
          setChatNotification(val => val - 1)
        }
      }
      else if(value.type === 'HelpNotification' && !isUserProvider(user)) {
        if(value.action === 'add') {
          setHelpNotification(val => val + 1)
        }
        else if(value.action === 'remove') {
          setHelpNotification(val => val - 1)
        }
      }
      else if(value.type === 'NewOrderNotification') {
        if(value.action === 'add') {
          setNewCases(val => val + 1)
        }
        else if(value.action === 'remove') {
          setNewCases(val => val - 1)
        }
      }
    })
    }

    return () => {
      if(isUserProvider(user)) {
        socket.emit('leaveRoom', {room: 'provider-pastilles-'+user.userGroupId})
      }
      else {
        socket.emit('leaveRoom', {room: 'admin-pastilles'})
      }
    }
  }, []);

  useEffect(() => {
    if(numberOfNewCases?.getAllOrdersForStaff?.totalCount) {
      setNewCases(numberOfNewCases.getAllOrdersForStaff.totalCount);
    }
  }, [numberOfNewCases])

  useEffect(() => {
    if(chatAndHelpNotificationCount?.getNotificationCount) {
      setChatNotification(chatAndHelpNotificationCount.getNotificationCount.chatNotification);
      setHelpNotification(chatAndHelpNotificationCount.getNotificationCount.helpNotification);
    }
  }, [chatAndHelpNotificationCount])

  return (
    <div
      style={{
        textAlign: "center",
        display: "grid",
        gap: "5px"
      }}
    >
      <div
        style={{
          paddingBottom: "2rem"
        }}
      >
        <img src={Logo}/>
        <div style={{color: "lightgrey"}}>
        <Text
          size="xs"
          style={{textAlign: "center"}}
        >
          v{packageJson.version}
        </Text>
        </div>
      </div>
      <div style={{marginBottom: '20px'}}>
        {(isUserStaff(user)) ?
          <>
            <Group mb={5}>
              <div
                style={{
                  fontWeight: 'bold',
                  cursor: 'pointer',
                  width: '100%',
                  textAlign: 'left',
                  paddingLeft: '20px'
                }}
                onClick={toggleNotification}
              >
                <IconBell style={{position: 'absolute', left: '10px'}}/> NOTIFICATIONS
                {(chatNotification > 0 || helpNotification > 0) && 
                  <span 
                    style={{
                      position: 'absolute',
                      width:'20px',
                      height:'20px',
                      borderRadius: '50px',
                      backgroundColor:'#F24034',
                      marginLeft: '10px',
                      fontSize: 'x-small',
                      textAlign: 'center',
                      lineHeight: '20px',
                      color: 'white'
                    }}
                  >
                    {(chatNotification ?? 0) + (helpNotification ?? 0) }
                  </span>
                }
                <span style={{position: 'absolute', right: '20px'}}>
                  { openedNotification ? 
                    <IconSquareRoundedArrowUpFilled/>
                    : <IconSquareRoundedArrowDownFilled/>
                  }
                </span>
              </div>
            </Group>
            <Collapse style={{paddingLeft: '10px'}} in={openedNotification}>
              <NavLink
                title='PROVIDER'
                onClick={() => nextUrl(`/chats?page=0`)}
                active={window.location.pathname.search("/chats") >= 0}
                notificationNumber={
                  chatNotification > 0 ?
                    chatNotification 
                    : undefined
                }
                icon={<IconMessageCircleSearch style={{position: 'absolute', left: '15px'}}/>}
              />
              <NavLink
                title='HELP'
                onClick={() => nextUrl(`/help-demands?page=0`)}
                active={window.location.pathname.search("/help-demands") >= 0}
                notificationNumber={
                  helpNotification > 0 ?
                  helpNotification 
                    : undefined
                }
                icon={<IconAlertTriangle style={{position: 'absolute', left: '15px'}}/>}
              />
            </Collapse>
          </>
          : 
          <NavLink
            title={'NOTIFICATIONS'}
            onClick={() => nextUrl(`/chats?page=0`)}
            active={window.location.pathname.search("/chats") >= 0}
            notificationNumber={
              chatNotification > 0 ?
              chatNotification 
                : undefined
            }
            icon={<IconBell style={{position: 'absolute', left: '10px'}}/>}
          />
        }
      </div>
      {(isUserAdmin(user)) &&
        <>
          <NavLink
            title="INBOX/NEW CASES"
            onClick={() => nextUrl(`/home?status=${OrderStatus.CONFIRMED}&page=0`)}
            active={searchParams.get('status') === OrderStatus.CONFIRMED}
            notificationNumber={newCases > 0 ? newCases : undefined}
          />
          <hr style={{width: "100%"}}/>
          <NavLink
            title="ALL"
            onClick={() => nextUrl('/home?status=ALL&page=0')}
            active={searchParams.get('status') === "ALL"}
          />
          <NavLink
            title={OrderStatus.ON_HOLD}
            onClick={() => nextUrl(`/home?status=${OrderStatus.ON_HOLD}&page=0`)}
            active={searchParams.get('status') === OrderStatus.ON_HOLD}
          />
          <NavLink
            title="TO SHIP"
            onClick={() => nextUrl(`/home?status=TO_SHIP&page=0`)}
            active={searchParams.get('status') === "TO_SHIP"}
          />
          <Group mb={5}>
            <div
              style={{
                fontWeight: 'bold',
                cursor: 'pointer',
                width: '100%',
                textAlign: 'left',
                paddingLeft: '20px'
              }}
              onClick={toggle}
            >
              <IconMenu2 style={{position: 'absolute', left: '10px'}}/> OTHER
              <span style={{position: 'absolute', right: '20px'}}>
                { opened ? 
                  <IconSquareRoundedArrowUpFilled/>
                  : <IconSquareRoundedArrowDownFilled/>
                }
              </span>
            </div>
          </Group>
          <Collapse style={{paddingLeft: '10px'}} in={opened}>
            <NavLink
              title={OrderStatus.WAITING_FOR_PRODUCTION}
              onClick={() => nextUrl(`/home?status=${OrderStatus.WAITING_FOR_PRODUCTION}&page=0`)}
              active={searchParams.get('status') === OrderStatus.WAITING_FOR_PRODUCTION}
            />
            <NavLink
              title={OrderStatus.PRODUCING}
              onClick={() => nextUrl(`/home?status=${OrderStatus.PRODUCING}&page=0`)}
              active={searchParams.get('status') === OrderStatus.PRODUCING}
            />
            <NavLink
              title={isUserProvider(user) ? "SHIPPED TO FRANCE" : OrderStatus.PRODUCING_IN_TRANSIT}
              onClick={() => nextUrl(`/home?status=${OrderStatus.PRODUCING_IN_TRANSIT}&page=0`)}
              active={searchParams.get('status') === OrderStatus.PRODUCING_IN_TRANSIT}
            />
            <NavLink
              title={OrderStatus.SHIPPED}
              onClick={() => nextUrl(`/home?status=${OrderStatus.SHIPPED}&page=0`)}
              active={searchParams.get('status') === OrderStatus.SHIPPED}
            />
            <NavLink
              title={OrderStatus.DELIVERED}
              onClick={() => nextUrl(`/home?status=${OrderStatus.DELIVERED}&page=0`)}
              active={searchParams.get('status') === OrderStatus.DELIVERED}
            />
            <NavLink
              title="ARCHIVED"
              onClick={() => nextUrl(`/home?status=ARCHIVED&page=0`)}
              active={searchParams.get('status') === "ARCHIVED"}
            />
          </Collapse>
        </>
      }
      {isUserProvider(user) &&
        <>
          <NavLink
            title={OrderStatus.WAITING_FOR_PRODUCTION}
            onClick={() => nextUrl(`/home?status=${OrderStatus.WAITING_FOR_PRODUCTION}&page=0`)}
            active={searchParams.get('status') === OrderStatus.WAITING_FOR_PRODUCTION}
            notificationNumber={newCases > 0 ? newCases : undefined}
          />
          <NavLink
            title={OrderStatus.PRODUCING}
            onClick={() => nextUrl(`/home?status=${OrderStatus.PRODUCING}&page=0`)}
            active={searchParams.get('status') === OrderStatus.PRODUCING}
          />
          <NavLink
            title={isUserProvider(user) ? "SHIPPED TO FRANCE" : OrderStatus.PRODUCING_IN_TRANSIT}
            onClick={() => nextUrl(`/home?status=${OrderStatus.PRODUCING_IN_TRANSIT}&page=0`)}
            active={searchParams.get('status') === OrderStatus.PRODUCING_IN_TRANSIT}
          />
          <NavLink
            title={OrderStatus.DELIVERED}
            onClick={() => nextUrl(`/home?status=${OrderStatus.DELIVERED}&page=0`)}
            active={searchParams.get('status') === OrderStatus.DELIVERED}
          />
          <NavLink
            title={OrderStatus.ON_HOLD}
            onClick={() => nextUrl(`/home?status=${OrderStatus.ON_HOLD}&page=0`)}
            active={searchParams.get('status') === OrderStatus.ON_HOLD}
          />
          <NavLink
            title="TO SHIP"
            onClick={() => nextUrl(`/home?status=TO_SHIP&page=0`)}
            active={searchParams.get('status') === "TO_SHIP"}
          />
          <NavLink
            title="ALL"
            onClick={() => nextUrl('/home?status=ALL&page=0')}
            active={searchParams.get('status') === "ALL"}
          />
        </>
      }
      <div
        style={{
          position: "absolute",
          bottom: "1rem",
          paddingLeft: "20px",
          display: "grid",
          gap: "5px"
        }}
      >
        {isUserStaff(user) &&
          <>
            {(isUserAdmin(user)) &&
              <>
                <NavLink
                  title={OrderStatus.DRAFT}
                  onClick={() => nextUrl(`/home?status=${OrderStatus.DRAFT}&page=0`)}
                  active={searchParams.get('status') === OrderStatus.DRAFT}
                />
                <hr style={{ width: "11.5vw" }}/>
              </>
            }
            <NavLink
              title="GROUPS"
              onClick={() => nextUrl(`/groups`)}
              active={window.location.pathname.search("/groups") >= 0}
              icon={<IconUsersGroup style={{position: 'absolute', left: '10px'}}/>}
            />
            <NavLink
              title="USERS"
              onClick={() => nextUrl(`/users`)}
              active={window.location.pathname.search("/users") >= 0}
              icon={<IconUsers style={{position: 'absolute', left: '10px'}}/>}
            />
            <hr style={{ width: "11.5vw" }}/>
          </>
        }
        <NavLink
          title="Log out"
          onClick={logOutFromLabs}
          icon={<IconLogout style={{position: 'absolute', left: '10px'}}/>}
        />
      </div>
    </div>
  );
};

export default Navbar;
