import { gql } from "@apollo/client";

export const GET_IMPLANT_ITEMS_CHOICES = gql(`
  query getImplantItemChoices(
    $where: ItemMaterialWhereInput
    $where2: ItemShadeWhereInput
  ) {
    getItemMaterialsWhere(where: $where) {
      id
      label
      implantCrown
      abutment
    }
    getItemShadesWhere(where: $where2) {
      id
      label
      shadeType
    }
    getAllImplantReferences {
      id
      brand
      label
      platforms
    }
  }
`);

export const ANATOMY_ITEM_CHOICE_DATA = gql`
  query getAnatomyItemChoices(
    $where: ItemMaterialWhereInput
    $where2: ItemShadeWhereInput
  ) {
    getItemMaterialsWhere(where: $where) {
      id
      label
      crown
      inlayCore
      bridge
      implantCrown
      fullDenture
      lay
      nightGuard
      partialDenture
      veneer
      abutment
    }
    getItemShadesWhere(where: $where2) {
      id
      label
      shadeType
    }
  }
`;

export const CREATE_ANATOMY_ITEM = gql`
  mutation CreateAnatomyItem($args: AnatomyItemCreateInput!) {
    createAnatomyItem(args: $args) {
      id
    }
  }
`;

export const GET_SCAN_POST_LIST = gql`
  query GetScanPostsData {
    getScanPostsData
  }
`;

export const UPDATE_ANATOMY_ITEM = gql`
  mutation UpdateAnatomyItem($data: AnatomyItemUpdateInput!, $where: AnatomyItemWhereUniqueInput!) {
    updateAnatomyItem(data: $data, where: $where) {
      id
    }
  }
`;

export const UPDATE_REMOVABLE_ITEM = gql`
  mutation updateRemovableItem($data: RemovableItemUpdateInput!, $where: RemovableItemWhereUniqueInput!) {
    updateRemovableItem(data: $data, where: $where) {
      id 
    }
  }
`;

export const UPDATE_IMPLANT_ITEM = gql`
  mutation UpdateImplantItem($data: ImplantItemUpdateInput!, $where: ImplantItemWhereUniqueInput!) {
    updateImplantItem(data: $data, where: $where) {
      id
    }
  }
`;

export const CREATE_IMPLANT_ITEM = gql`
  mutation CreateImplantItem($args: ImplantItemCreateInput!) {
    createImplantItem(args: $args) {
      id
    }
  }
`;

export const DELETE_MANY_IMPLANT_ITEMS = gql`
  mutation DeleteManyImplantItem($where: ImplantItemWhereInput) {
    deleteManyImplantItem(where: $where)
  }
`;

export const DELETE_MANY_ANATOMY_ITEMS = gql`
  mutation DeleteManyAnatomyItem($where: AnatomyItemWhereInput) {
    deleteManyAnatomyItem(where: $where)
  }
`;

export const CREATE_REMOVABLE_ITEM = gql`
  mutation CreateRemovableItem($args: RemovableItemCreateInput!) {
    createRemovableItem(args: $args) {
      id
    }
  }
`;

export const DELETE_MANY_REMOVABLE_ITEMS = gql`
  mutation DeleteManyRemovableItems($where: RemovableItemWhereInput) {
    deleteManyRemovableItems(where: $where)
  }
`;

export const UPDATE_MANY_REMOVABLE_ITEMS = gql`
  mutation UpdateManyRemovableItems(
    $productId: String!,
    $itemMaterialId: String,
    $gingivaShadeId: String,
    $teethShadeId: String,
    $dentistNotes: String,
    $workflowType: String
  ) {
    updateManyRemovableItems(
      productId: $productId,
      itemMaterialId: $itemMaterialId,
      gingivaShadeId: $gingivaShadeId,
      teethShadeId: $teethShadeId,
      dentistNotes: $dentistNotes,
      workflowType: $workflowType
    )
  }
`;
