import React, {
    useEffect,
  useState,
} from "react";
import {
  Stepper,
} from "@mantine/core";

import CreateNewImplantImplantInfo from "./createNewImplantImplantInfo";
import CreateNewImplantAbutmentInfo from "./createNewImplantAbutmentInfo";
import CreateNewImplantCrownInfo from "./createNewImplantCrownInfo";
import PreferenceSelection from "../preferenceSelection";
import { ItemType, AbutmentType, TeethShadeType, TeethShadeSide } from "../../../types/enums";
import { useLocation } from "react-router-dom";
import {
  getFixedProsthesisPreferences,
  excludePonticFromBridgeImplantTeeth,
} from "../../../utils/item.utils";

interface CreateNewImplantBridgeProps {
  materialFormChoices;
  anatomyItemChoicesData;
  accountProductPreferences;
  order;
  abutmentMaterials;
  implantItemChoicesData;
  updateImplantItem;
  createImplantItem;
  product_id: string;
  updateProduct;
  getUserProductPreferences;
  onValidate;
  deleteManyImplantItem;
};

const CreateNewImplantBridge = (props: CreateNewImplantBridgeProps) => {

  const [active, setActive] = useState(0);
  const [newImplantItemId, setNewImplantItemId] = useState<string | null>(null);
  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));
  const [itemPreferences, setItemPreferences] = useState(null);
  const location = useLocation();
  const savedImplantItem = (location?.state?.product?.implantItem ?? []).find((item) => item.itemType === ItemType.IMPLANT);
  const savedImplantBridgeItem = (location?.state?.product?.implantItem ?? []).find((item) => item.itemType === ItemType.BRIDGE_IMPLANT);
  const savedImplantPonticItem = (location?.state?.product?.implantItem ?? []).find((item) => item.itemType === ItemType.BRIDGE_PONTIC);
  const product = location?.state?.product;

  useEffect(() => {
    const loadItemPreferences = async() => {
      const userProductPreferences = await props.getUserProductPreferences();
      setItemPreferences(
        getFixedProsthesisPreferences(
          product.productType,
          userProductPreferences,
        )
      );
    }
    loadItemPreferences();
  }, []);

  return (
    <>
      <Stepper active={active} onStepClick={setActive}>
        <Stepper.Step label="Implant">
          <CreateNewImplantImplantInfo
            implantItemChoicesData={props.implantItemChoicesData}
            savedItem={savedImplantItem}
            onSubmit={async(data, isImplantReferenceNotFound) => {
              if (savedImplantItem?.id || newImplantItemId){
                await props.updateImplantItem({
                  variables: {
                    where: {
                      id: savedImplantItem?.id ?? newImplantItemId,
                    },
                    data: {
                      implantReference: (!isImplantReferenceNotFound && data.implantReferenceId)
                        ? {
                          connect: {
                            id: data.implantReferenceId,
                          }
                        }
                        : {
                          disconnect: {
                            id: {
                              equals: data.implantReferenceId
                            },
                          }
                        },
                      implantPlatform: {
                        set: (!isImplantReferenceNotFound && data.platform) ? data.platform : null,
                      },
                      implantReferenceSuggestion:
                        data.implantReferenceSuggestion &&
                        data.implantReferenceSuggestion !== "" &&
                        isImplantReferenceNotFound
                          ? { set: `${data.implantBrandSuggestion ?? ""} - ${data.implantReferenceSuggestion} - ${data.implantPlatformSuggestion}`}
                          : null,
                      scanBodySuggestion: data.scanBodySuggestion
                      ? {
                        set: data.scanBodySuggestion
                      } : undefined,
                    },
                  }
                })
              } else {
                const req = await props.createImplantItem({
                  variables: {
                    args: {
                      implantReference: data.implantReferenceId
                        ? {
                          connect: {
                            id: data.implantReferenceId,
                          }
                        }
                        : undefined,
                      itemType: ItemType.IMPLANT,
                      implantPlatform: data.platform,
                      scanBodySuggestion: data.scanBodySuggestion,
                      product: {
                        connect: {
                          id: props.product_id
                        },
                      },
                      teeth: product?.teeth,
                      implantReferenceSuggestion:
                        data.implantReferenceSuggestion &&
                        data.implantReferenceSuggestion !== "" &&
                        isImplantReferenceNotFound
                          ? `${data.implantBrandSuggestion ?? ""} - ${data.implantReferenceSuggestion} - ${data.implantPlatformSuggestion}`
                          : undefined,
                    }
                  }
                });
                setNewImplantItemId(req?.data?.createImplantItem?.id)
              }
              nextStep();
            }}
          />
        </Stepper.Step>
        <Stepper.Step label="Pilier">
          <CreateNewImplantAbutmentInfo
            onSubmit={async(data) => {
              await props.updateImplantItem({
                variables: {
                  where: {
                    id: savedImplantItem?.id ?? newImplantItemId
                  },
                  data: {
                    abutmentType: (data.standardAbutmentType && data.abutmentType === AbutmentType.STANDARD)
                      ? { set: data.standardAbutmentType }
                      : data.abutmentType
                        ? { set: data.abutmentType }
                        : undefined,
                  abutmentMaterial: data.abutmentMaterial
                    ? {
                      connect: {
                        id: data.abutmentMaterial
                      }
                    }
                    : undefined,
                  standardAbutmentSuggestion: (data.standardAbutmentSuggestion && data.standardAbutmentSuggestion !== "")
                      ? { set: data.standardAbutmentSuggestion }
                      : undefined,
                  },
                }
              });
              nextStep();
            }}
            previousStep={() => prevStep()}
            savedItem={savedImplantItem}
          />
        </Stepper.Step>
        <Stepper.Step label="Bridge">
          <CreateNewImplantCrownInfo
            materialFormChoices={props.materialFormChoices}
            anatomyItemChoicesData={props.anatomyItemChoicesData}
            accountProductPreferences={props.accountProductPreferences}
            order={props.order}
            previousStep={() => prevStep()}
            savedItem={savedImplantBridgeItem}
            savedPonticItem={savedImplantPonticItem}
            itemType={ItemType.BRIDGE_IMPLANT}
            onSubmit={async(data, selectedPonticTeeth) => {
              const isMultiShade = data?.shade ? false : true;
              await props.deleteManyImplantItem({
                variables: {
                  where: {
                    productId: {
                      equals: product.id,
                    },
                    itemType: {
                      in: [
                        ItemType.IMPLANT_CROWN,
                        ItemType.SPLINTED_CROWN_IMPLANT,
                        ItemType.BRIDGE_IMPLANT,
                        ItemType.BRIDGE_PONTIC,
                      ],
                    },
                  },
                },
              });
              await props.updateImplantItem({
                variables: {
                  where: {
                    id: savedImplantItem?.id ?? newImplantItemId,
                  },
                  data: {
                    teeth: excludePonticFromBridgeImplantTeeth(product?.teeth, selectedPonticTeeth)
                  }
                }
              });
              await props.createImplantItem({
                variables: {
                  args: {
                    itemMaterial: {
                      connect: {
                        id: data.material,
                      },
                    },
                    itemShade:
                      data.shade && !isMultiShade
                        ? {
                            connect: {
                              id: data.shade,
                            },
                          }
                        : undefined,
                    teeth: excludePonticFromBridgeImplantTeeth(
                      product.teeth,
                      selectedPonticTeeth,
                    ),
                    itemType: ItemType.BRIDGE_IMPLANT,
                    product: {
                      connect: {
                        id: product.id,
                      },
                    },
                    retentionType: data.retentionType,
                    teethshadeType:
                      isMultiShade
                        ? TeethShadeType.MULTI_SHADE
                        : TeethShadeType.SINGLE_SHADE,
                    multiShadeInfo:
                      isMultiShade
                        ? {
                            createMany: {
                              data: [
                                ...(data.gingivalShade
                                  ? [
                                      {
                                        itemShadeId: data.gingivalShade,
                                        teethShadeSide: TeethShadeSide.GINGIVAL,
                                      },
                                    ]
                                  : []),
                                ...(data.baseShade
                                  ? [
                                      {
                                        itemShadeId: data.baseShade,
                                        teethShadeSide: TeethShadeSide.BASE,
                                      },
                                    ]
                                  : []),
                                ...(data.incisalShade
                                  ? [
                                      {
                                        itemShadeId: data.incisalShade,
                                        teethShadeSide: TeethShadeSide.INCISAL,
                                      },
                                    ]
                                  : []),
                              ],
                            },
                          }
                        : undefined,
                  },
                },
              });
              if ((selectedPonticTeeth ?? []).length > 0){
                await props.createImplantItem({
                  variables: {
                    args: {
                      itemMaterial: {
                        connect: {
                          id: data.material,
                        },
                      },
                      itemShade:
                        data.shade && !isMultiShade
                          ? {
                              connect: {
                                id: data.shade,
                              },
                            }
                          : undefined,
                      teeth: selectedPonticTeeth,
                      itemType: ItemType.BRIDGE_PONTIC,
                      product: {
                        connect: {
                          id: product.id,
                        },
                      },
                      retentionType: data.retentionType,
                      teethshadeType:
                        isMultiShade
                          ? TeethShadeType.MULTI_SHADE
                          : TeethShadeType.SINGLE_SHADE,
                      multiShadeInfo:
                        isMultiShade
                          ? {
                              createMany: {
                                data: [
                                  ...(data.gingivalShade
                                    ? [
                                        {
                                          itemShadeId: data.gingivalShade,
                                          teethShadeSide: TeethShadeSide.GINGIVAL,
                                        },
                                      ]
                                    : []),
                                  ...(data.baseShade
                                    ? [
                                        {
                                          itemShadeId: data.baseShade,
                                          teethShadeSide: TeethShadeSide.BASE,
                                        },
                                      ]
                                    : []),
                                  ...(data.incisalShade
                                    ? [
                                        {
                                          itemShadeId: data.incisalShade,
                                          teethShadeSide: TeethShadeSide.INCISAL,
                                        },
                                      ]
                                    : []),
                                ],
                              },
                            }
                          : undefined,
                    },
                  },
                });
              }
              nextStep();
            }}
          />
        </Stepper.Step>
        <Stepper.Step label="Preferences">
          <PreferenceSelection
            productType={ItemType.BRIDGE_IMPLANT}
            previousStep={() => prevStep()}
            itemPreferences={itemPreferences}
            onSubmit={async(data) => {
              await props.updateProduct({
                variables: {
                  where: {
                    id: product?.id,
                  },
                  data: {
                    itemPreferences: {
                      set: {...itemPreferences, ...data},
                    },
                  },
                }
              })
              props.onValidate();
            }}
          />
        </Stepper.Step>
        <Stepper.Completed>
          Completed, click back button to get to previous step
        </Stepper.Completed>
      </Stepper>
    </>
  )
};

export default CreateNewImplantBridge;
