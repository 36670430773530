import { useForm } from "react-hook-form";
import {
  Button,
} from "@mantine/core";

import CreateProductFromScanOnly from "./scan-only";
import { STYLE } from "../../types/constants";

const CreateNewScanOnly = ({
  onSubmit,
}) => {
  const rhf = useForm();
  return (
    <>
      <CreateProductFromScanOnly rhf={rhf}/>
      <div style={{ textAlign: "center" }}>
        <Button style={{ backgroundColor: STYLE.primary }} onClick={rhf.handleSubmit(onSubmit)}>
          Save
        </Button>
      </div>
    </>
  );
};

export default CreateNewScanOnly;
