import { useState } from "react";
import { useMutation } from '@apollo/client';
import { useForm } from 'react-hook-form';
import {
  Button,
  Space,
  Box,
  Text,
} from '@mantine/core';
import { notifications } from '@mantine/notifications';
import { MantineProvider } from '@mantine/core';

import {
  ItemType,
  SelectInputRef,
  STYLE,
  DentalArchesSvg,
  ArchType,
  getArchFromTeeth,
  UpperArchTeeth,
  LowerArchTeeth,
  getAllNonSplintedCrown,
  getAllPossibleSplintedCrown,
  ImplantItem,
  TeethShadeSide,
  TeethShadeType,
  Product,
  UPDATE_IMPLANT_ITEM,
  CREATE_ANATOMY_ITEM,
  DELETE_MANY_REMOVABLE_ITEMS,
  excludePonticFromBridgeImplantTeeth,
  getItemsOfBridgeImplant,
  getTeethsFromUpperArch,
  getTeethsFromLowerArch,
} from '@jasper/shared';

import {
  CREATE_PRODUCT,
  UPDATE_PRODUCT,
  UPDATE_ALL_ITEM_TEETH_OF_PRODUCTS,
  DELETE_MANY_ANATOMY_ITEMS,
  DELETE_MANY_IMPLANT_ITEM,
  CREATE_REMOVABLE_ITEM,
} from '../../../gql/';
import { AnatomyItem } from "@jasper/shared/types/interfaces";
import { CREATE_IMPLANT_ITEM, UPDATE_ANATOMY_ITEM } from "@jasper/shared/gql/items";
import { RemovableItem } from "@prisma/client";

const CreateProductComponent = ({ closeModal, orderId, refetch, product, isRemovableItem }) => {
  const [createProduct] = useMutation(CREATE_PRODUCT);
  const [updateProduct] = useMutation(UPDATE_PRODUCT);
  const [updateAllTeethOfItem] = useMutation(UPDATE_ALL_ITEM_TEETH_OF_PRODUCTS);
  const [updateAnatomyItemGQL] = useMutation(UPDATE_ANATOMY_ITEM);
  const [deleteManyAnatomyItems] = useMutation(DELETE_MANY_ANATOMY_ITEMS);
  const [createAnatomyItemGQL] = useMutation(CREATE_ANATOMY_ITEM);
  const [createImplantItemGQL] = useMutation(CREATE_IMPLANT_ITEM);
  const [updateImplantItemGQL] = useMutation(UPDATE_IMPLANT_ITEM);
  const [deleteManyImplantItemsGQL] = useMutation(DELETE_MANY_IMPLANT_ITEM);
  const [updateImplanItemGQL] = useMutation(UPDATE_IMPLANT_ITEM);
  const [deleteManyRemovableItemGQL] = useMutation(DELETE_MANY_REMOVABLE_ITEMS);
  const [createRemovableItemGQL] = useMutation(CREATE_REMOVABLE_ITEM);

  const [selectedTeeth, setSelectedTeeth] = useState<number[]>(product?.teeth ?? []);
  const [selectedPonticTeeth, setSelectedPonticTeeth] = useState<number[]>(
    (product?.implantItem ?? []).find((item: ImplantItem) => item.itemType === ItemType.BRIDGE_PONTIC)?.teeth ?? []
  );

  const {
    formState: { errors },
    handleSubmit,
    control,
    watch,
  } = useForm({
    defaultValues: {
      productType: product?.productType,
    }
  });

  const watchProductType = watch("productType");

  const deleteManyRemovableItem = async(product_id: string, variables: any) => {
    if (product_id){
      await deleteManyRemovableItemGQL({
        variables: variables,
      })
    }
  };

  const createRemovableItem = async(product_id: string, variables: any) => {
    if (product_id){
      await createRemovableItemGQL({
        variables: variables,
      })
    }
  };

  const updateImplantItem = async(item_id: string, variables: any) => {
    if (item_id){
      await updateImplantItemGQL({
        variables: variables,
      })
    }
  }

  const deleteAllImplantItems = async(product_id: string, variables: any) => {
    if (product_id){
      await deleteManyImplantItemsGQL({
        variables: variables,
      });
    }
  }
 
  const createImplantItem = async(
    item: {
      material: string,
      shade?: string,
      multishade?: {
        gingivalShade?: string,
        baseShade?: string,
        incisalShade?: string
      }
    },
    isMultiShade: boolean,
    itemType: ItemType,
    teeth: number[],
    product_id: string,
  ) => {
    await createImplantItemGQL({
      variables: {
        args: {
          itemMaterial: item.material
            ? {
                connect: {
                  id: item.material,
                },
              }
            : undefined,
          itemShade: (item.shade && !isMultiShade && item.material !== "METAL_NON_PRECIOUS")
            ? {
                connect: {
                  id: item.shade,
                },
              }
            : undefined,
          itemType: itemType,
          teeth: teeth,
          product: {
            connect: {
              id: product_id,
            },
          },
          teethshadeType: isMultiShade && item.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MULTI_SHADE : TeethShadeType.SINGLE_SHADE,
          multiShadeInfo: isMultiShade && item.material !== "METAL_NON_PRECIOUS"
          ? {
            createMany: {
              data: [
                ...item.multishade?.gingivalShade
                ? [{
                  itemShadeId: item.multishade.gingivalShade,
                  teethShadeSide: TeethShadeSide.GINGIVAL,
                }] : [],
                ...item.multishade?.baseShade
                ? [{
                  itemShadeId: item.multishade.baseShade,
                  teethShadeSide: TeethShadeSide.BASE,
                }] : [],
                ...item.multishade?.incisalShade
                ? [{
                  itemShadeId: item.multishade.incisalShade,
                  teethShadeSide: TeethShadeSide.INCISAL,
                }] : [],
              ],
            },
          } : undefined,
        },
      },
    })
  };

  const updateAnatomyItem = async(item_id: string, variables: any) => {
    if (item_id){
      await updateAnatomyItemGQL({
        variables: variables,
      })
    }
  }

  const deleteAllAnatomyItems = async(product_id: string, variables: any) => {
    if (product_id){
      await deleteManyAnatomyItems({
        variables: variables,
      });
    }
  }

  const updateImplanItem = async(itemId: string, teeth: number[]) => {
    await updateImplanItemGQL({
      variables: {
        where: {
          id: itemId
        },
        data: {
          teeth: teeth,
        }
      }
    })
  };

  const createAnatomyItem = async(
    item: {
      material: string,
      shade?: string,
      multishade?: {
        gingivalShade?: string,
        baseShade?: string,
        incisalShade?: string
      }
    },
    isMultiShade: boolean,
    itemType: ItemType,
    teeth: number[],
    product_id: string,
  ) => {
    await createAnatomyItemGQL({
      variables: {
        args: {
          itemMaterial: item.material
            ? {
                connect: {
                  id: item.material,
                },
              }
            : undefined,
          itemShade: (item.shade && !isMultiShade && item.material !== "METAL_NON_PRECIOUS")
            ? {
                connect: {
                  id: item.shade,
                },
              }
            : undefined,
          itemType: itemType,
          teeth: teeth,
          product: {
            connect: {
              id: product_id,
            },
          },
          teethshadeType: isMultiShade && item.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MULTI_SHADE : TeethShadeType.SINGLE_SHADE,
          multiShadeInfo: isMultiShade && item.material !== "METAL_NON_PRECIOUS"
          ? {
            createMany: {
              data: [
                ...item.multishade?.gingivalShade
                ? [{
                  itemShadeId: item.multishade.gingivalShade,
                  teethShadeSide: TeethShadeSide.GINGIVAL,
                }] : [],
                ...item.multishade?.baseShade
                ? [{
                  itemShadeId: item.multishade.baseShade,
                  teethShadeSide: TeethShadeSide.BASE,
                }] : [],
                ...item.multishade?.incisalShade
                ? [{
                  itemShadeId: item.multishade.incisalShade,
                  teethShadeSide: TeethShadeSide.INCISAL,
                }] : [],
              ],
            },
          } : undefined,
        },
      },
    })
  };

  const createOrUpdateProduct = async(product: Product, productType: ItemType, selectedTeeth: number[], orderId: string) => {
    if (product?.id){
      await updateProduct({
        variables: {
          data: {
            productType: {
              set: productType,
            },
            teeth: selectedTeeth,
          },
          where: {
            id: product.id
          }
        }
      });
    } else {
      await createProduct({
        variables:{
          args: {
            teeth: selectedTeeth,
            productType: productType,
            order: {
              connect: {
                id: orderId
              }
            },
            itemPreferences: undefined
          }
        }
      });
      notifications.show({
        title: "Product created",
        color: "green",
        message: "",
      });
    }
  }

  const updateItemsForAnatomyItemsWithSplintedCrowns = async() => {
    /*
     Usually, when we update a product, we update the teeth it item
     But for the case of a splinted we have a specific logic to regroup adjacent teeth.
     So, if the teeth are adjacent, we add in the splinted item list, however, if they aren't the tooth is added in crown
    */
    const item = (product?.anatomyItem ?? []).length > 0 ? product.anatomyItem.find((item: AnatomyItem) => item.itemType === ItemType.CROWN || item.itemType === ItemType.SPLINTED_CROWN) : undefined;
    if (!item){
      return;
    }
    await deleteAllAnatomyItems(
      product?.id,
      {
        where: {
          productId: {
            equals: product?.id,
          },
          itemType: {
            in: [ItemType.CROWN, ItemType.SPLINTED_CROWN]
          }
        }
      }
    );
    const allNonSplintedCrown = getAllNonSplintedCrown(selectedTeeth ?? []);
    const allSplinteCrown: number[][] = getAllPossibleSplintedCrown(selectedTeeth ?? []);
    Promise.all(allSplinteCrown.map(async(splintedCrownTeeth: number[]) => {
      await createAnatomyItem(
        {
          material: item.itemMaterial?.id,
          shade: item.itemShade?.id,
          multishade: {
            gingivalShade: (item?.multiShadeInfo ?? []).find(
              (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
                multiShadeInfo.teethShadeSide === TeethShadeSide.GINGIVAL,
            )?.itemShade?.id ?? undefined,
            baseShade: (item?.multiShadeInfo ?? []).find(
              (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
                multiShadeInfo.teethShadeSide === TeethShadeSide.BASE,
            )?.itemShade?.id ?? undefined,
            incisalShade: (item?.multiShadeInfo ?? []).find(
              (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
                multiShadeInfo.teethShadeSide === TeethShadeSide.INCISAL,
            )?.itemShade?.id ?? undefined,
          },
        },
        !item.itemShade?.id,
        ItemType.SPLINTED_CROWN,
        splintedCrownTeeth,
        product?.id,
      );
    }));
    if (allNonSplintedCrown.length > 0){
      await createAnatomyItem(
          {
            material: item.itemMaterial?.id,
            shade: item.itemShade?.id,
            multishade: item.multiShadeInfo,
          },
          !item.itemShade?.id,
          ItemType.CROWN,
          allNonSplintedCrown,
          product?.id,
      );
    }
    if (product.productType === ItemType.INLAY_CORE){
      const itemInlayCore = (product?.implantItem ?? []).find((item: AnatomyItem) => item.itemType === ItemType.INLAY_CORE);
      await updateAnatomyItem(
        itemInlayCore?.id,
        {
          where: {
            id: itemInlayCore?.id,
          },
          data: {
            teeth: selectedTeeth,
          }
        }
      )
    }
  }

  const updateItemsForImplantItemsWithSplintedCrowns = async() => {
    const item = (product?.implantItem ?? []).length > 0 ? product.implantItem.find((item: ImplantItem) => item.itemType === ItemType.IMPLANT_CROWN || item.itemType === ItemType.SPLINTED_CROWN_IMPLANT) : undefined;
    if (!item){
      return;
    }
    await deleteAllImplantItems(
      product?.id,
      {
        where: {
          productId: {
            equals: product?.id,
          },
          itemType: {
            in: [ItemType.IMPLANT_CROWN, ItemType.SPLINTED_CROWN_IMPLANT]
          }
        }
      }
    );
    const allNonSplintedCrown = getAllNonSplintedCrown(selectedTeeth ?? []);
    const allSplinteCrown: number[][] = getAllPossibleSplintedCrown(selectedTeeth ?? []);
    Promise.all(allSplinteCrown.map(async(splintedCrownTeeth: number[]) => {
      await createImplantItem(
        {
          material: item.itemMaterial?.id,
          shade: item.itemShade?.id,
          multishade: {
            gingivalShade: (item?.multiShadeInfo ?? []).find(
              (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
                multiShadeInfo.teethShadeSide === TeethShadeSide.GINGIVAL,
            )?.itemShade?.id ?? undefined,
            baseShade: (item?.multiShadeInfo ?? []).find(
              (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
                multiShadeInfo.teethShadeSide === TeethShadeSide.BASE,
            )?.itemShade?.id ?? undefined,
            incisalShade: (item?.multiShadeInfo ?? []).find(
              (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
                multiShadeInfo.teethShadeSide === TeethShadeSide.INCISAL,
            )?.itemShade?.id ?? undefined,
          },
        },
        !item.itemShade?.id,
        ItemType.SPLINTED_CROWN_IMPLANT,
        splintedCrownTeeth,
        product?.id,
      );
    }));
    if (allNonSplintedCrown.length > 0){
      await createImplantItem(
          {
            material: item.itemMaterial?.id,
            shade: item.itemShade?.id,
            multishade: item.multiShadeInfo,
          },
          !item.itemShade?.id,
          ItemType.IMPLANT_CROWN,
          allNonSplintedCrown,
          product?.id,
      );
    }
    const itemAbument = (product?.implantItem ?? []).find((item: ImplantItem) => item.itemType === ItemType.IMPLANT);
    await updateImplantItem(
      itemAbument?.id,
      {
        where: {
          id: itemAbument?.id,
        },
        data: {
          teeth: selectedTeeth,
        }
      }
    )
  }

  const submit = async(data) => {
    try {
      await createOrUpdateProduct(product, product?.productType ?? data.productType, selectedTeeth, orderId);
      /*
       Usually, when we update a product, we update the teeth it item
       But for the case of a splinted we have a specific logic to regroup adjacent teeth.
       So, if the teeth are adjacent, we add in the splinted item list, however, if they aren't the tooth is added in crown
      */
      if (
        (
          product?.productType === ItemType.CROWN
          || product?.productType === ItemType.INLAY_CORE
        )
        && (product?.anatomyItem ?? []).filter((item: { itemType: ItemType }) => item.itemType === ItemType.SPLINTED_CROWN).length > 0
      ){
        await updateItemsForAnatomyItemsWithSplintedCrowns();
      } else if (
        product?.productType === ItemType.IMPLANT
        && (product?.implantItem ?? []).filter((item: { itemType: ItemType }) => item.itemType === ItemType.SPLINTED_CROWN_IMPLANT).length > 0
      ) {
        await updateItemsForImplantItemsWithSplintedCrowns();
      } else {
        if (product?.id){
          await updateAllTeethOfItem({
            variables: {
              productId: product?.id,
              teeth: JSON.stringify(selectedTeeth)
            }
          })
        }
      };
      if (product?.productType === ItemType.INLAY_CORE){
        const itemInlayCore = (product?.anatomyItem ?? []).find((item: AnatomyItem) => item.itemType === ItemType.INLAY_CORE);
        await updateAnatomyItem(
          itemInlayCore?.id,
          {
            where: {
              id: itemInlayCore?.id,
            },
            data: {
              teeth: selectedTeeth,
            }
          }
        )
      } else if (product?.productType === ItemType.BRIDGE_IMPLANT) {
        const { bridgePontic, bridgeImplant, abutment } = getItemsOfBridgeImplant(product?.implantItem ?? []);
        const teethWithoutPontic = excludePonticFromBridgeImplantTeeth(selectedTeeth, selectedPonticTeeth);
        if (bridgePontic){
          await updateImplanItem(bridgePontic.id, selectedPonticTeeth);
        }
        if (bridgeImplant) {
          await updateImplanItem(bridgeImplant.id, teethWithoutPontic);
        }
        if (abutment) {
          await updateImplanItem(abutment.id, teethWithoutPontic);
        }
      } else if (product?.productType === ItemType.PARTIAL_DENTURE || product?.productType === ItemType.FULL_DENTURE) {
        const savedItemanyArch = (product?.removableItem ?? []).find((item: RemovableItem) => item.itemType === product?.productType);
        if (savedItemanyArch){
          await deleteManyRemovableItem(
            product?.id,
            {
              where: {
                productId: {
                  equals: product?.id,
                },
                itemType: {
                  in: [product?.productType]
                }
              }
            }
          );
          const lowerTeeth = getTeethsFromLowerArch(selectedTeeth);
          const upperTeeth = getTeethsFromUpperArch(selectedTeeth);
          if (lowerTeeth.length > 0){
            await createRemovableItem(
              product?.id,
              {
                args: {
                  teeth: lowerTeeth,
                  teethToManufacture: {
                    set: lowerTeeth
                  },
                  product: {
                    connect: {
                      id: product?.id,
                    }
                  },
                  itemType: product?.productType,
                  isReplacement: savedItemanyArch.isReplacement,
                  itemMaterial: {
                    connect: {
                      id: savedItemanyArch?.itemMaterial?.id,
                    }
                  },
                  gingivaShade: {
                    connect: {
                      id: savedItemanyArch?.gingivaShade?.id,
                    }
                  },
                  teethShade: {
                    connect: {
                      id: savedItemanyArch?.teethShade?.id,
                    }
                  },
                  dentistNotes: savedItemanyArch?.dentistNotes,
                  workflowType: savedItemanyArch.workflowType,
                }
              }
            )
          }
          if (upperTeeth.length > 0){
            await createRemovableItem(
              product?.id,
              {
                args: {
                  teeth: upperTeeth,
                  teethToManufacture: {
                    set: upperTeeth
                  },
                  product: {
                    connect: {
                      id: product?.id,
                    }
                  },
                  itemType: product?.productType,
                  isReplacement: savedItemanyArch.isReplacement,
                  itemMaterial: {
                    connect: {
                      id: savedItemanyArch?.itemMaterial?.id,
                    }
                  },
                  gingivaShade: {
                    connect: {
                      id: savedItemanyArch?.gingivaShade?.id,
                    }
                  },
                  teethShade: {
                    connect: {
                      id: savedItemanyArch?.teethShade?.id,
                    }
                  },
                  dentistNotes: savedItemanyArch?.dentistNotes,
                  workflowType: savedItemanyArch.workflowType,
                }
              }
            )
          }
        }
      } else {
        if (product?.id){
          await updateAllTeethOfItem({
            variables: {
              productId: product?.id,
              teeth: JSON.stringify(selectedTeeth)
            }
          })
        }
      };
       refetch();
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to create or update product",
        color: "red",
        message: "",
      });
    }
    closeModal();
  };

  return (
    <MantineProvider>
      <SelectInputRef
        name="productType"
        data={Object.keys(ItemType).sort()}
        label="Product type"
        errors={errors}
        control={control}
        searchable
        clearable
      />
      <Space h="md"/>
      <Text>Select product teeth:</Text>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DentalArchesSvg
          teeth={selectedTeeth}
          width={275}
          height={366}
          onClick={(tooth) => {
            if (isRemovableItem(watchProductType) && watchProductType !== ItemType.PARTIAL_DENTURE){
              if (getArchFromTeeth([tooth]) === ArchType.MAXILLAIRE){
                if (selectedTeeth.includes(tooth)){
                  setSelectedTeeth(selectedTeeth.filter((t) => !UpperArchTeeth.includes(t)));
                } else {
                  setSelectedTeeth([...UpperArchTeeth, ...selectedTeeth])
                }
              } else {
                if (selectedTeeth.includes(tooth)){
                  setSelectedTeeth(selectedTeeth.filter((t) => !LowerArchTeeth.includes(t)));
                } else {
                  setSelectedTeeth([...LowerArchTeeth, ...selectedTeeth])
                }
              }
            } else {
              const index = selectedTeeth.indexOf(tooth);
              if (index >= 0){
                setSelectedTeeth(selectedTeeth.filter((t) => t !== tooth));
                return;
              }
              setSelectedTeeth([...selectedTeeth, tooth])
            }
          }}
        />
      </Box>
      <Space h="md"/>
      {(watchProductType === ItemType.BRIDGE_IMPLANT && (product?.implantItem ?? []).length > 0) &&
        <>
          <Text>Select pontic teeth:</Text>
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
            style={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <DentalArchesSvg
              teeth={selectedPonticTeeth}
              width={275}
              height={366}
              onClick={(tooth) => {
                const index = selectedPonticTeeth.indexOf(tooth);
                if (index >= 0){
                  setSelectedPonticTeeth(selectedPonticTeeth.filter((t) => t !== tooth));
                  return;
                }
                setSelectedPonticTeeth([...selectedPonticTeeth, tooth])
              }}
            />
          </Box>
        </>
      }
      <Space h="md"/>
      <div style={{ textAlign: 'center' }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={handleSubmit(submit)}
        >
          Submit
        </Button>
      </div>
    </MantineProvider>
  );
};

export default CreateProductComponent;
