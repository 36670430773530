import { useEffect } from "react";
import {
  useMutation,
  useQuery,
} from "@apollo/client";
import {
  useForm,
} from "react-hook-form";
import {
  Button,
  Title,
  Grid,
} from "@mantine/core";
import { notifications } from "@mantine/notifications";
import {
  useNavigate,
  useParams,
} from "react-router-dom";

import {
  CREATE_GROUP,
  GET_GROUP_BY_ID,
  UPDATE_USER_GROUP,
} from "../../gql/groups";

import {
  TextInputRef,
  SelectInputRef,
  STYLE,
  GroupType,
} from "@jasper/shared";
import { argv0 } from "process";
import { group } from "console";

const GroupNew = () => {

  const [createGroup] = useMutation(CREATE_GROUP);
  const [updateGroupMutation] = useMutation(UPDATE_USER_GROUP);
  const navigate = useNavigate(); 
  const { group_id } = useParams();

  const { data: groupInfo } = useQuery(
    GET_GROUP_BY_ID,
    {
      variables: {
        where: {
          id: group_id,
        }
      },
      fetchPolicy: "network-only",
    }
  );

  const {
    formState: { errors },
    control,
    handleSubmit,
    setValue,
  } = useForm({
    defaultValues: {
      name: "",
    },
  });

  useEffect(() => {
    const groupAddress = (groupInfo?.getUserGroupsWhereUnique?.addresses ?? []).length > 0
      ? groupInfo?.getUserGroupsWhereUnique?.addresses[0]
      : null;
    setValue("name", groupInfo?.getUserGroupsWhereUnique?.name);
    setValue("type", groupInfo?.getUserGroupsWhereUnique?.groupType);
    if (groupAddress){
      setValue("addressName", groupAddress?.name);
      setValue("streetNumber", groupAddress?.streetNumber);
      setValue("streetName", groupAddress?.streetName);
      setValue("city", groupAddress?.city);
      setValue("postcode", groupAddress?.postcode);
      setValue("country", groupAddress?.country);
    }
  }, [groupInfo?.getUserGroupsWhereUnique])

  const updateGroup = async(data, existingGroup) => {
    try {
      await updateGroupMutation({
        variables: {
          where: {
            id: existingGroup?.id,
          },
          data: {
            name: {
              set: data.name
            },
            groupType: {
              set: data.type
            },
            addresses: {
              update: [
                {
                  where: {
                    id: ((existingGroup?.addresses ?? []).length > 0) ? existingGroup.addresses[0]?.id : undefined
                  },
                  data: {
                    city: {
                      set: data.city,
                    },
                    country: {
                      set: data.country,
                    },
                    name: {
                      set: data.addressName,
                    },
                    postcode: {
                      set: data.postcode,
                    },
                    streetName: {
                      set: data.streetName,
                    },
                    streetNumber: {
                      set: data.streetNumber,
                    },
                  }
                }
              ]
            }
          }
        }
      });
      navigate(`/groups/${groupInfo?.getUserGroupsWhereUnique?.id}`);
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to update group",
        color: "red",
        message: "",
      }); 
    }
  };

  const createNewGroup = async(data) => {
    try {
      const req = await createGroup({
        variables: {
          args: {
            name: data.name,
            groupType: data.type,
            addresses: {
              create: [
                {
                  city: data.city,
                  country: data.country,
                  name: data.addressName,
                  postcode: data.postcode,
                  streetName: data.streetName,
                  streetNumber: data.streetNumber,
                }
              ]
            },
          }
        }
      });
      navigate(`/groups/${req?.data?.createUserGroup?.id}`);
    } catch (e) {
      console.error(e);
      notifications.show({
        title: "Error while trying to create a new group, this name may already exist",
        color: "red",
        message: "",
      }); 
    }
  };

  const onSubmit = async (data) => {
    if (groupInfo?.getUserGroupsWhereUnique?.id){
      updateGroup(data, groupInfo?.getUserGroupsWhereUnique);
    } else {
      createNewGroup(data);
    }
  };

  return (
    <>
      <Title fw={200} style={{ paddingBottom: "1rem" }}>General info</Title>
      <TextInputRef
        label="Group name"
        name="name"
        errors={errors}
        control={control}
      />
      <SelectInputRef
        label="Group type"
        name="type"
        data={Object.keys(GroupType)}
        errors={errors}
        control={control}
      />
      <Title fw={200} style={{ paddingBottom: "1rem" }}>Address</Title>
      <Grid>
        <Grid.Col span={4}>
          <TextInputRef
            name="addressName"
            label="Address name"
            control={control}
            errors={errors}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInputRef
            name="streetNumber"
            label="Street Number"
            control={control}
            errors={errors}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInputRef
            name="streetName"
            label="Street Name"
            control={control}
            errors={errors}
          />
        </Grid.Col>
        </Grid>
        <Grid>
        <Grid.Col span={4}>
          <TextInputRef
            name="city"
            label="City"
            control={control}
            errors={errors}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInputRef
            name="postcode"
            label="Postcode"
            control={control}
            errors={errors}
          />
        </Grid.Col>
        <Grid.Col span={4}>
          <TextInputRef
            name="country"
            label="Country"
            control={control}
            errors={errors}
          />
        </Grid.Col>
      </Grid>
      <div
        style={{ textAlign: "center", marginTop: "2rem" }}
      >
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={handleSubmit(onSubmit)}
        >
          Save
        </Button>
      </div>
    </>
  )
};

export default GroupNew;
