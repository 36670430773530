import {
  ItemType,
  ProductItemType,
} from "@jasper/shared";
import { TeethShadeSide } from "@jasper/shared/types/enums";

const AnatomyItemComponent = ({ item, index, onDelete, onUpdate, onUpdateTeeth }) => {
  return (
    <>
      <li>
        <span style={{ fontWeight: 'bold' }}>
          Item {index + 1}
        </span>
        &nbsp;
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => onDelete()}>
          (delete item)
        </span>
        {(
          item.itemType !== ItemType.INLAY_CORE
          && item.itemType !== ItemType.CROWN
          && item.itemType !== ItemType.LAY
          && item.itemType !== ItemType.BRIDGE
          && item.itemType !== ItemType.SPLINTED_CROWN
          && item.itemType !== ItemType.VENEER
        ) &&
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => onUpdate()}>
            (update item)
          </span>
        }
      </li>
      <div style={{ marginLeft: '3rem' }}>
        <li>
          <span style={{ fontWeight: 'bold' }}>
            ItemType:
          </span>
          &nbsp;{item.itemType}
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>
            Teeth:
          </span>
          &nbsp;{item?.teeth?.join(", ")}
        </li>
        {item?.itemMaterial?.label &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              ItemMaterial:
            </span>
            &nbsp;{item?.itemMaterial?.label}
          </li>
        }
        {item?.itemShade?.label &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              ItemShade:
            </span>
            &nbsp;{item?.itemShade?.label}
          </li>
        }
        {(item?.multiShadeInfo ?? []).length > 0 &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              Gradient Shade:
            </span>
            <div style={{ marginLeft: "3rem" }}>
              <li>
                <span style={{ fontWeight: 'bold' }}>Gingival:</span>
                &nbsp;{(item?.multiShadeInfo ?? []).find((shade) => shade.teethShadeSide === TeethShadeSide.GINGIVAL)?.itemShade?.label}
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}>Base:</span>
                &nbsp;{(item?.multiShadeInfo ?? []).find((shade) => shade.teethShadeSide === TeethShadeSide.BASE)?.itemShade?.label}
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}>Incisal:</span>
                &nbsp;{(item?.multiShadeInfo ?? []).find((shade) => shade.teethShadeSide === TeethShadeSide.INCISAL)?.itemShade?.label}
              </li>
            </div>
          </li>
        }
        {(item.itemType === ItemType.INLAY_CORE || item.itemType === ItemType.RICHMON_CROWN) &&
          <>
            <li>
              <span style={{ fontWeight: 'bold' }}>
                InlayCore has clavette:
              </span>
              &nbsp;{item?.inlayCoreHasClavette ? "yes" : "no"}
            </li>
            {item?.inlayCoreScanPost &&
              <li>
                <span style={{ fontWeight: 'bold' }}>
                  Inlay Core Scan Post:
                </span>
                &nbsp;{item.inlayCoreScanPost}
              </li>
            }
            {item?.inlayCoreMaterial?.label &&
              <li>
                <span style={{ fontWeight: 'bold' }}>
                  Inlay Core Material:
                </span>
                &nbsp;{item?.inlayCoreMaterial?.label}
              </li>
            }
          </>
        }
      </div>
    </>
  );
};

export default AnatomyItemComponent;
