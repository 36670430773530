import React from 'react';
import { Title, Text, Paper, Button, Group, Box } from '@mantine/core';
import { OrderComment, User } from '../types/interfaces';
import { FileList } from '../stories/FileList/FileList';
import { OrderStatus } from '../types/enums';
import { isUserProvider, isUserStaff } from "../utils/user.utils";
import { STYLE } from '../types/constants';

interface DoctorNotesProps {
  user: User;
  orderCommentList: OrderComment[];
  orderStatus: OrderStatus
  setCommentToTranslate: (comment: OrderComment) => void;
  setOpenTranslationModal: (open: boolean) => void;
  getPresignedDownloadUrl: (file: string) => void;
}

const DoctorNotes = ({
  user,
  orderCommentList = [],
  orderStatus,
  setCommentToTranslate,
  setOpenTranslationModal,
  getPresignedDownloadUrl
}: DoctorNotesProps) => {

  const renderFiles = (comment: OrderComment) => {
    if ((comment?.files ?? []).length === 0) return null;

    return (
      <Box mt={2}>
        <FileList
          blueLink={true}
          files={comment.files}
          deleteOneFile={() => {}}
          downloadOneFile={getPresignedDownloadUrl}
          showTitle={false}
        />
      </Box>
    );
  };

  const renderProviderView = () => {
    if (!isUserProvider(user)) return null;
    
    return orderCommentList.map((comment: OrderComment) => {
      const hasTranslatedComment = Boolean(comment.translatedComment);
      const hasFiles = (comment?.files ?? []).length > 0;
      const shouldShowComment = hasTranslatedComment || (!comment.comment && hasFiles);
      
      if (!shouldShowComment) return null;

      return (
        <Paper key={comment.id} shadow="xxs" p="md" withBorder mt="sm">
          <Text>Comment:</Text>
          <Paper p="xs" bg="#eff2f5" mb="md">
            {comment.translatedComment && (
              <Text style={{ whiteSpace: 'pre-wrap' }}>{comment.translatedComment}</Text>
            )}
            {hasFiles && renderFiles(comment)}
          </Paper>
        </Paper>
      );
    });
  };
  
  const renderStaffView = () => {
    if (!isUserStaff(user)) return null;
    
    return orderCommentList.map((comment: OrderComment, index: number) => (
      <Paper key={comment.id} shadow="xxs" p="md" withBorder mt="sm">
        <Text fw={700} mb="xs">Comment {index + 1} ({comment?.type || 'Unknown'})</Text>

        <Text>Comment:</Text>
        <Paper p="xs" bg="#eff2f5" mb="md">
          <Text style={{ whiteSpace: 'pre-wrap' }}>{comment.comment}</Text>
          {renderFiles(comment)}
        </Paper>

        {comment.translatedComment || comment.comment ? (
          <>
            <Text>Translation:</Text>
            <Paper p="xs" bg="#eff2f5">
              <Text style={{ whiteSpace: 'pre-wrap' }}>{comment.translatedComment || '-'}</Text>
            </Paper>
          </>
        ) : null}
        {(comment.comment) && ((!comment.translatedComment) || (orderStatus === OrderStatus.CONFIRMED)) && (
          <Group mt="xs">
              <Button
                size="xs"
                onClick={() => {
                  setCommentToTranslate(comment);
                  setOpenTranslationModal(true);
                }}
                style={{ backgroundColor: STYLE.primary }}
              >
                {!comment.translatedComment ? "Translate" : "Update translation"}
              </Button>
          </Group>
        )}
      </Paper>
    ));
  };

  return (
    <>
      <Title order={5} mb="md">Doctor notes</Title>
      
      {renderProviderView()}
      {renderStaffView()}
      
      {orderCommentList.length === 0 && (
        <Text>There are no notes on this order</Text>
      )}
    </>
  );
};

export default DoctorNotes;