import React from "react";
import {
  Title,
  Space,
  Text,
  Group,
  Button,
} from "@mantine/core";
import { useForm } from "react-hook-form";

import {
  getItemTypeLabel,
} from "../../utils/product.utils";
import {
  OcclusalContactTightnessLabels,
  ProximalContactTightnessLabels,
  OcclusalStainingLabels,
  NoOcclusalClearanceLabels,
  InterproximalContactDesignLabels,
  AnteriorPosteriorPonticDesignLabels,
} from "../../types/constants";
import SelectInputRef from "../SelectInputRef";
import {
  ItemType
} from "../../types/enums";

const PreferenceSelection = ({ productType, onSubmit, previousStep, itemPreferences }) => {
  const {
    control,
    handleSubmit,
    formState: {errors},
  } = useForm({
    defaultValues: itemPreferences,
  });

  return (
    <> 
      <Title order={2}>
        Sélectionnez vos préférences de {getItemTypeLabel(productType).toLowerCase()} pour votre commande
      </Title>
      <Space h="xl" />
      <SelectInputRef
        label="Intensité du contact occlusal"
        name="occlusalContactTightness"
        data={OcclusalContactTightnessLabels}
        control={control}
        errors={errors}
        required={false}
      />
      <SelectInputRef
        label="Intensité du contact proximal"
        name="proximalContactTightness"
        subText="Quelle intensité de contact proximal ?"
        data={ProximalContactTightnessLabels}
        control={control}
        errors={errors}
        required={false}
      />
      <SelectInputRef
        label="Coloration occlusale"
        name="occlusalStaining"
        subText="Quelle intensité de contact proximal ?"
        data={OcclusalStainingLabels}
        control={control}
        errors={errors}
        required={false}
      />
      <SelectInputRef
        label="Quelles sont vos préférences de coloration occlusale ?"
        name="noOcclusalClearance"
        data={NoOcclusalClearanceLabels}
        control={control}
        errors={errors}
        required={false}
      />
      <SelectInputRef
        label="Étendue du point de contact inter dentaire"
        name="interproximalContactDesign"
        data={InterproximalContactDesignLabels}
        control={control}
        errors={errors}
        required={false}
      />
      {productType === ItemType.BRIDGE &&
        <SelectInputRef
          label="Design des pontiques antérieurs et postérieurs"
          name="anteriorPosteriorPonticDesign"
          subText="Quel design souhaitez-vous pour les pontiques antérieurs et postérieurs?"
          data={AnteriorPosteriorPonticDesignLabels}
          control={control}
          errors={errors}
          required={false}
        />
      }
      <Group justify="space-between" mt="xl">
        <Button variant="default" onClick={previousStep}>Previous step</Button>
        <Button onClick={handleSubmit(onSubmit)}>Next step</Button>
      </Group>
    </>
  )
};

export default PreferenceSelection;
