import {
  useSearchParams,
  useParams
} from "react-router-dom";
import {
  useQuery,
  useMutation,
  useLazyQuery,
} from "@apollo/client";
import {
  useNavigate,
  useLocation,
} from "react-router-dom";

import CreateNewInlayCoreComponent from './CreateNewInlayCoreComponent';
import CreateNewLay from './CreateNewLay';
import CreateNewCrown from './CreateNewCrown';
import CreateNewBridge from './CreateNewBridge';
import CreateNewSplint from './removable-items/CreateNewSplint';
import PartialDentureWorkflow from './removable-items/partial-denture/PartialDentureworkflow';
import FullDentureWorkflow from "./removable-items/full-denture/fullDentureWorkflow";
import CreateNewImplantCrown from "./implants/CreateNewImplantCrown";
import CreateNewImplantBridge from "./implants/CreateNewImplantBridge";
import CreateNewScanOnly from "./CreateNewScanOnly";

import {
  ItemType,
  TeethShadeType,
  ItemShadeType,
  TeethShadeSide,
  ArchTypeEn,
} from "../../types/enums";
import {
  GET_ORDER_BY_UNIQUE_ATTRIBUTE,
} from "../../gql/orders";
import {
  ANATOMY_ITEM_CHOICE_DATA,
  CREATE_ANATOMY_ITEM,
  UPDATE_ANATOMY_ITEM,
  UPDATE_REMOVABLE_ITEM,
  CREATE_REMOVABLE_ITEM,
  DELETE_MANY_REMOVABLE_ITEMS,
  UPDATE_MANY_REMOVABLE_ITEMS,
  GET_IMPLANT_ITEMS_CHOICES,
  CREATE_IMPLANT_ITEM,
  UPDATE_IMPLANT_ITEM,
  DELETE_MANY_IMPLANT_ITEMS,
  DELETE_MANY_ANATOMY_ITEMS,
} from "../../gql/items";
import {
  UPDATE_PRODUCT,
} from "../../gql/product";
import {
  GET_USER_AND_ACCOUNT_PRODUCT_PREFERENCES,
  GET_USER_PRODUCT_PREFERENCES,
} from "../../gql/users";
import {
  getAnatomyItemMaterialChoices,
  getAnatomyItemChoiceWhere,
  getAllPossibleSplintedCrown,
  getAllNonSplintedCrown,
} from "../../utils/product.utils";
import {
  getTeethsFromUpperArch,
  getTeethsFromLowerArch,
  deduceFinalItemTypeOfFullDenture,
  isFullDentureType,
  getGingivaShadeFromAllShades,
} from "../../utils/item.utils";
import {
  FullDentureWorkflowToSubmit,
} from "./removable-items/full-denture/fullDentureInterfaces";
import { LowerArchTeeth, UpperArchTeeth } from "../../types/constants";

const NewItemComponent = () => {
  const [searchParams] = useSearchParams();
  const { product_id, order_id } = useParams();
  const location = useLocation();
  const navigate = useNavigate();

  const productType = searchParams.get("itemType");
  const itemId = searchParams.get("itemId");

  const [createAnatomyItem] = useMutation(CREATE_ANATOMY_ITEM);
  const [updateAnatomyItem] = useMutation(UPDATE_ANATOMY_ITEM);
  const [deleteManyAnatomyItems] = useMutation(DELETE_MANY_ANATOMY_ITEMS);
  const [createRemovableItem] = useMutation(CREATE_REMOVABLE_ITEM);
  const [updateRemovableItem] = useMutation(UPDATE_REMOVABLE_ITEM);
  const [updateManyRemovableItem] = useMutation(UPDATE_MANY_REMOVABLE_ITEMS);
  const [deleteManyRemovableItem] = useMutation(DELETE_MANY_REMOVABLE_ITEMS);
  const [updateImplantItem] = useMutation(UPDATE_IMPLANT_ITEM);
  const [createImplantItem] = useMutation(CREATE_IMPLANT_ITEM);
  const [deleteManyImplantItems] = useMutation(DELETE_MANY_IMPLANT_ITEMS);
  const [updateProduct] = useMutation(UPDATE_PRODUCT);

  const { data: order, refetch } = useQuery(
    GET_ORDER_BY_UNIQUE_ATTRIBUTE,
    {
      variables: {
        where: {
          id: order_id,
        }
      }
    }
  );

  const { data: implantItemChoicesData } = useQuery(
    GET_IMPLANT_ITEMS_CHOICES,
    {
      fetchPolicy: "network-only"
    }
  );

  const { data: anatomyItemChoicesData } = useQuery(
    ANATOMY_ITEM_CHOICE_DATA,
    {
      variables: {
        where: getAnatomyItemChoiceWhere(productType),
      },
      fetchPolicy: "network-only"
    }
  );
  const { data: accountProductPreferences } = useQuery(GET_USER_AND_ACCOUNT_PRODUCT_PREFERENCES);

  const [queryUserProductPreferences] = useLazyQuery(GET_USER_PRODUCT_PREFERENCES);

  const materialFormChoices = anatomyItemChoicesData
    ? getAnatomyItemMaterialChoices(anatomyItemChoicesData, productType)
    : []
  const product = (order?.getOrderByUniqueAttribute?.products ?? []).find((product) => product.id === product_id);

  switch(productType){
    case ItemType.INLAY_CORE:
      return (
        <CreateNewInlayCoreComponent
          productType={productType}
          order={order?.getOrderByUniqueAttribute}
          product_id={product_id}
          refetch={refetch}
          itemId={itemId}
          materialFormChoices={materialFormChoices}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
        />
      );
    case ItemType.LAY:
    case ItemType.INLAY:
    case ItemType.ONLAY:
    case ItemType.OVERLAY:
      return (
        <CreateNewLay
          productType={productType}
          materialFormChoices={materialFormChoices}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          order={order?.getOrderByUniqueAttribute}
          product_id={product_id}
          refetch={refetch}
        />
      );
    case ItemType.SPLINT:
      return (
        <CreateNewSplint
          order={order?.getOrderByUniqueAttribute}
          product_id={product_id}
          refetch={refetch}
        />
      );
    case ItemType.BRIDGE:
      return (
        <CreateNewBridge
          materialFormChoices={materialFormChoices}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          order={order?.getOrderByUniqueAttribute}
          product_id={product_id}
          onSubmit={async(data, watchIsMultiShade, item) => {
            if (item?.id){
              if ((item?.multiShadeInfo ?? []).length > 0){
                await updateAnatomyItem({
                  variables: {
                    where: {
                      id: item.id
                    },
                    data: {
                      multiShadeInfo: {
                        deleteMany: [
                          {
                            id: {
                              in: (item?.multiShadeInfo).map((multiShadeInfo: { id: string }) => multiShadeInfo.id)
                            }
                          }
                        ]
                      }
                    }
                  }
                })
              }
              await updateAnatomyItem({
                variables: {
                  where: {
                    id: item.id
                  },
                  data: {
                    itemMaterial: data.material
                      ? {
                          connect: {
                            id: data.material,
                          },
                        }
                      : undefined,
                    itemShade: (data.shade && !watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS")
                      ? {
                          connect: {
                            id: data.shade,
                          },
                        }
                      : (data.shade)
                        ? {
                          disconnect: {
                            id: {
                              equals: data.shade,
                            }
                          }
                        }
                        : undefined,
                    teeth: product.teeth,
                    teethshadeType: watchIsMultiShade
                      ? {
                        set: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MULTI_SHADE : TeethShadeType.SINGLE_SHADE
                      } : undefined,
                    multiShadeInfo: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
                    ? {
                      createMany: {
                        data: [
                          ...data.gingivalShade
                          ? [{
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.GINGIVAL,
                          }] : [],
                          ...data.baseShade
                          ? [{
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.BASE,
                          }] : [],
                          ...data.incisalShade
                          ? [{
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.INCISAL,
                          }] : [],
                        ],
                      },
                    } : undefined,
                  }
                }
              });
            } else {
              await createAnatomyItem({
                variables: {
                  args: {
                    itemMaterial: data.material
                      ? {
                          connect: {
                            id: data.material,
                          },
                        }
                      : undefined,
                    itemShade: (data.shade && !watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS")
                      ? {
                          connect: {
                            id: data.shade,
                          },
                        }
                      : (data.shade)
                        ? {
                          disconnect: {
                            id: {
                              equals: data.shade,
                            }
                          }
                        }
                        : undefined,
                    itemType: ItemType.BRIDGE,
                    teeth: product.teeth,
                    product: {
                      connect: {
                        id: product.id,
                      },
                    },
                    teethshadeType: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MULTI_SHADE : TeethShadeType.SINGLE_SHADE,
                    multiShadeInfo: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
                    ? {
                      createMany: {
                        data: [
                          ...data.gingivalShade
                          ? [{
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.GINGIVAL,
                          }] : [],
                          ...data.baseShade
                          ? [{
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.BASE,
                          }] : [],
                          ...data.incisalShade
                          ? [{
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.INCISAL,
                          }] : [],
                        ],
                      },
                    } : undefined,
                  },
                },
              });
            }
            await refetch();
            navigate(`/orders/${order?.getOrderByUniqueAttribute?.id}/edit/`);
          }}
        />
      );
    case ItemType.VENEER:
      return (
        <CreateNewCrown
          materialFormChoices={materialFormChoices}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          order={order?.getOrderByUniqueAttribute}
          product_id={product_id}
          itemType={ItemType.VENEER}
          onSubmit={async(data, watchIsMultiShade: boolean, item) => {
            if (item?.id){
              if ((item?.multiShadeInfo ?? []).length > 0){
                await updateAnatomyItem({
                  variables: {
                    where: {
                      id: item.id
                    },
                    data: {
                      multiShadeInfo: {
                        deleteMany: [
                          {
                            id: {
                              in: (item?.multiShadeInfo).map((multiShadeInfo: { id: string }) => multiShadeInfo.id)
                            }
                          }
                        ]
                      }
                    }
                  }
                })
              };
              await updateAnatomyItem({
                variables: {
                  where: {
                    id: item?.id,
                  },
                  data: {
                    itemMaterial: data.material
                      ? {
                          connect: {
                            id: data.material,
                          },
                        }
                      : undefined,
                    itemShade: (data.shade && !watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS")
                      ? {
                          connect: {
                            id: data.shade,
                          },
                        }
                      : undefined,
                    teethshadeType: {
                      set: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MULTI_SHADE : TeethShadeType.SINGLE_SHADE
                    },
                    multiShadeInfo: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
                    ? {
                      createMany: {
                        data: [
                          ...data.gingivalShade
                          ? [{
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.GINGIVAL,
                          }] : [],
                          ...data.baseShade
                          ? [{
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.BASE,
                          }] : [],
                          ...data.incisalShade
                          ? [{
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.INCISAL,
                          }] : [],
                        ],
                      },
                    } : undefined,
                  }
                }
              })
            } else {
              await createAnatomyItem({
                variables: {
                  args: {
                    itemMaterial: data.material
                      ? {
                          connect: {
                            id: data.material,
                          },
                        }
                      : undefined,
                    itemShade: (data.shade && !watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS")
                      ? {
                          connect: {
                            id: data.shade,
                          },
                        }
                      : undefined,
                    itemType: ItemType.VENEER,
                    teeth: product?.teeth,
                    product: {
                      connect: {
                        id: product.id,
                      },
                    },
                    teethshadeType: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MULTI_SHADE : TeethShadeType.SINGLE_SHADE,
                    multiShadeInfo: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
                    ? {
                      createMany: {
                        data: [
                          ...data.gingivalShade
                          ? [{
                            itemShadeId: data.gingivalShade,
                            teethShadeSide: TeethShadeSide.GINGIVAL,
                          }] : [],
                          ...data.baseShade
                          ? [{
                            itemShadeId: data.baseShade,
                            teethShadeSide: TeethShadeSide.BASE,
                          }] : [],
                          ...data.incisalShade
                          ? [{
                            itemShadeId: data.incisalShade,
                            teethShadeSide: TeethShadeSide.INCISAL,
                          }] : [],
                        ],
                      },
                    } : undefined,
                  }
                }
              })
            }
            await refetch();
            navigate(`/orders/${order?.getOrderByUniqueAttribute?.id}/edit/`);
          }}
        />
      )
    case ItemType.CROWN:
      return (
        <CreateNewCrown
          materialFormChoices={materialFormChoices}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          order={order?.getOrderByUniqueAttribute}
          product_id={product_id}
          itemType={ItemType.CROWN}
          onSubmit={async(data, watchIsMultiShade, item) => {
              await deleteManyAnatomyItems({
                variables: {
                  where: {
                    productId: {
                      equals: product.id,
                    },
                  }
                }
              });
              const allNonSplintedCrown = getAllNonSplintedCrown(product?.teeth ?? []);
              if (data.isSplintedCrown){
                const allSplinteCrown: number[][] = getAllPossibleSplintedCrown(product?.teeth ?? []);
                Promise.all(allSplinteCrown.map(async(splintedCrownTeeth: number[]) => {
                  await createAnatomyItem({
                    variables: {
                      args: {
                        itemMaterial: data.material
                          ? {
                              connect: {
                                id: data.material,
                              },
                            }
                          : undefined,
                        itemShade: (data.shade && !watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS")
                          ? {
                              connect: {
                                id: data.shade,
                              },
                            }
                          : undefined,
                        itemType: ItemType.SPLINTED_CROWN,
                        teeth: splintedCrownTeeth,
                        product: {
                          connect: {
                            id: product.id,
                          },
                        },
                        teethshadeType: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MULTI_SHADE : TeethShadeType.SINGLE_SHADE,
                        multiShadeInfo: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
                        ? {
                          createMany: {
                            data: [
                              ...data.gingivalShade
                              ? [{
                                itemShadeId: data.gingivalShade,
                                teethShadeSide: TeethShadeSide.GINGIVAL,
                              }] : [],
                              ...data.baseShade
                              ? [{
                                itemShadeId: data.baseShade,
                                teethShadeSide: TeethShadeSide.BASE,
                              }] : [],
                              ...data.incisalShade
                              ? [{
                                itemShadeId: data.incisalShade,
                                teethShadeSide: TeethShadeSide.INCISAL,
                              }] : [],
                            ],
                          },
                        } : undefined,
                      },
                    },
                  })
                }));
              }
              if (!data.isSplintedCrown || allNonSplintedCrown.length > 0){
                await createAnatomyItem({
                  variables: {
                    args: {
                      itemMaterial: data.material
                        ? {
                            connect: {
                              id: data.material,
                            },
                          }
                        : undefined,
                      itemShade: (data.shade && !watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS")
                        ? {
                            connect: {
                              id: data.shade,
                            },
                          }
                        : undefined,
                      itemType: ItemType.CROWN,
                      teeth: data.isSplintedCrown
                        ? allNonSplintedCrown
                        : product.teeth,
                      product: {
                        connect: {
                          id: product.id,
                        },
                      },
                      teethshadeType: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS" ? TeethShadeType.MULTI_SHADE : TeethShadeType.SINGLE_SHADE,
                      multiShadeInfo: watchIsMultiShade && data.material !== "METAL_NON_PRECIOUS"
                      ? {
                        createMany: {
                          data: [
                            ...data.gingivalShade
                            ? [{
                              itemShadeId: data.gingivalShade,
                              teethShadeSide: TeethShadeSide.GINGIVAL,
                            }] : [],
                            ...data.baseShade
                            ? [{
                              itemShadeId: data.baseShade,
                              teethShadeSide: TeethShadeSide.BASE,
                            }] : [],
                            ...data.incisalShade
                            ? [{
                              itemShadeId: data.incisalShade,
                              teethShadeSide: TeethShadeSide.INCISAL,
                            }] : [],
                          ],
                        },
                      } : undefined,
                    },
                  },
                });
            }
            await refetch();
            navigate(`/orders/${order?.getOrderByUniqueAttribute?.id}/edit/`);
          }}
        />
      );
    case ItemType.FULL_DENTURE:
    case ItemType.IMMEDIATE_DENTURE:
    case ItemType.FULL_DENTURE_WAX_BITE:
      return (
        <FullDentureWorkflow
          user_id={order?.getOrderByUniqueAttribute?.user?.id}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          gingivaShadeChoices={getGingivaShadeFromAllShades(anatomyItemChoicesData?.getItemShadesWhere ?? [])}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          order={order?.getOrderByUniqueAttribute}
          onSubmit={async(data: FullDentureWorkflowToSubmit) => {
            const savedItem = (location?.state?.product?.removableItem ?? [])
              .find((item: { itemType: ItemType }) => isFullDentureType(item.itemType));
            await updateProduct({
              variables: {
                where: {
                  id: product.id,
                },
                data: {
                  itemPreferences: {
                    set: {
                      teethShape: data.teethShape,
                    }
                  },
                },
              },
            });
            const finalItemType = deduceFinalItemTypeOfFullDenture(data.productSpecificType, data.newFullDentureStep, data.isReplacement);
            if (savedItem){
              await updateRemovableItem({
                variables: {
                  where: {
                    id: savedItem?.id,
                  },
                  data: {
                    teethShade: data.shade
                      ? {
                        connect: {
                          id: data.shade,
                        }
                      } : {
                        disconnect: {
                          id: data.shade,
                        }
                      },
                    teeth: product.teeth,
                    product: {
                      connect: {
                        id: product.id,
                      }
                    },
                    isReplacement: {
                      set: data.isReplacement
                    },
                    teethToManufacture: {
                      set: product.teeth,
                    },
                    itemType: {
                        set: finalItemType
                    },
                    gingivaShade: data.gingivaShade
                      ? {
                        connect: {
                          id: data.gingivaShade,
                        }
                      } : {
                        disconnect: {
                          id: data.gingivaShade,
                        }
                      },
                      itemMaterial: finalItemType === ItemType.FULL_DENTURE
                          ? {
                              connect: {
                                id: "ACRYLIC",
                              },
                            }
                          : undefined,
                      },
                    }
              })
            }
            else {
              await createRemovableItem({
                variables: {
                  args: {
                    teethShade: data.shade
                      ? {
                        connect: {
                          id: data.shade,
                        }
                      } : undefined,
                    product: {
                      connect: {
                        id: product.id,
                      }
                    },
                    teeth: product.teeth,
                    teethToManufacture: {
                      set: product.teeth,
                    },
                    isReplacement: data.isReplacement,
                    itemType: finalItemType,
                    gingivaShade: data.gingivaShade
                      ? {
                        connect: {
                          id: data.gingivaShade,
                        }
                      } : undefined,
                      itemMaterial: finalItemType === ItemType.FULL_DENTURE
                          ? {
                              connect: {
                                id: "ACRYLIC",
                              },
                            }
                          : undefined,
                  }
                }
              });
            }
            await refetch();
            navigate(`/orders/${order?.getOrderByUniqueAttribute?.id}/edit/`);
          }}
        />
      );
    case ItemType.BRIDGE_IMPLANT:
      return (
        <CreateNewImplantBridge
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          order={order?.getOrderByUniqueAttribute}
          materialFormChoices={materialFormChoices}
          implantItemChoicesData={implantItemChoicesData}
          deleteManyImplantItem={async (data) => {
            await deleteManyImplantItems(data);
          }}
          updateImplantItem={async(data) => {
            await updateImplantItem(data);
          }}
          createImplantItem={async(data) => {
            return await createImplantItem(data);
          }}
          product_id={product_id}
          updateProduct={async(data) => {
            await updateProduct(data);
          }}
          getUserProductPreferences={async() => {
            const preferences = await queryUserProductPreferences({
              variables: {
                where: {
                  dentistId: {
                    equals: order?.user?.id,
                  },
                },
              },
            });
            return (preferences?.data?.getUserProductPreferences);
          }}
          onValidate={() => navigate(`/orders/${order?.getOrderByUniqueAttribute?.id}/edit/`)}
        />
      );
    case ItemType.IMPLANT:
      return (
        <CreateNewImplantCrown
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          order={order?.getOrderByUniqueAttribute}
          materialFormChoices={materialFormChoices}
          abutmentMaterials={[]}
          implantItemChoicesData={implantItemChoicesData}
          deleteManyImplantItem={async (data) => {
            await deleteManyImplantItems(data);
          }}
          updateImplantItem={async(data) => {
            await updateImplantItem(data);
          }}
          createImplantItem={async(data) => {
            return await createImplantItem(data);
          }}
          product_id={product_id}
          updateProduct={async(data) => {
            await updateProduct(data);
          }}
          getUserProductPreferences={async() => {
            const preferences = await queryUserProductPreferences({
              variables: {
                where: {
                  dentistId: {
                    equals: order?.user?.id,
                  },
                },
              },
            });
            return (preferences?.data?.getUserProductPreferences);
          }}
          onValidate={() => navigate(`/orders/${order?.getOrderByUniqueAttribute?.id}/edit/`)}
        />
      );
    case ItemType.SCAN_ONLY:
      return (
        <CreateNewScanOnly
          onSubmit={async(values) => {
            await deleteManyRemovableItem({
              variables: {
                where: {
                  productId: {
                    equals: product?.id,
                  },
                },
              },
            });
            if (values.scanOnlyType === ItemType.RETENTION_WIRE) {
              await updateProduct({
                variables: {
                  where: {
                    id: product?.id,
                  },
                  data: {
                    teeth:
                      values?.archType === ArchTypeEn.UPPER_ARCH
                        ? UpperArchTeeth
                        : values?.archType === ArchTypeEn.LOWER_ARCH
                          ? LowerArchTeeth
                          : [...UpperArchTeeth, ...LowerArchTeeth],
                  },
                },
              });
              if (
                values?.archType === ArchTypeEn.UPPER_ARCH ||
                values?.archType === ArchTypeEn.BOTH_ARCH
              ) {
                await createRemovableItem({
                  variables: {
                    args: {
                      itemType: values.scanOnlyType,
                      teeth: UpperArchTeeth,
                      teethToManufacture: {
                        set: UpperArchTeeth,
                      },
                      product: {
                        connect: {
                          id: product?.id,
                        },
                      },
                    },
                  },
                });
              }
              if (
                values?.archType === ArchTypeEn.LOWER_ARCH ||
                values?.archType === ArchTypeEn.BOTH_ARCH
              ) {
                await createRemovableItem({
                  variables: {
                    args: {
                      itemType: values.scanOnlyType,
                      teeth: LowerArchTeeth,
                      teethToManufacture: {
                        set: LowerArchTeeth,
                      },
                      product: {
                        connect: {
                          id: product?.id,
                        },
                      },
                    },
                  },
                });
              }
            } else {
              await createRemovableItem({
                variables: {
                  args: {
                    itemType: values.scanOnlyType,
                    waxUpType: values.waxUpType,
                    product: {
                      connect: {
                        id: product?.id,
                      },
                    },
                  },
                },
              });
            }
            navigate(`/orders/${order?.getOrderByUniqueAttribute?.id}/edit/`);
          }}
        />
      );
    case ItemType.PARTIAL_DENTURE:
      return (
        <PartialDentureWorkflow
          materialChoices={materialFormChoices ?? []}
          gingivaShadeChoices={(anatomyItemChoicesData?.getItemShadesWhere ?? []).filter((shade) => shade.shadeType === ItemShadeType.GINGIVA).map((shade) => ({ value: shade.id, label: shade.label }))}
          order={order?.getOrderByUniqueAttribute}
          accountProductPreferences={accountProductPreferences?.getAccountProductPreferences}
          anatomyItemChoicesData={anatomyItemChoicesData ?? []}
          updateManyRemovableItem={async(data) => {
            await updateManyRemovableItem({
              variables: data,
            })
          }}
          updateOrder={async(data, teeth: number[])  => {
            const upperTeeth = getTeethsFromUpperArch(teeth ?? []);
            const lowerTeeth = getTeethsFromLowerArch(teeth ?? []);
            await deleteManyRemovableItem({
              variables: {
                where: {
                  productId: {
                    equals: location?.state?.product?.id,
                  }
                }
              },
            });
            await updateProduct({
              variables: {
                where: {
                  id: location?.state?.product?.id
                },
                data: {
                  teeth: teeth,
                }
              }
            });
            if (upperTeeth.length > 0){
              data.teeth = upperTeeth;
              data.teethToManufacture = {
                set: upperTeeth,
              }
              await createRemovableItem({
                variables: {
                  args: data,
                }
              });
            }
            if (lowerTeeth.length > 0){
              data.teeth = lowerTeeth;
              data.teethToManufacture = {
                set: lowerTeeth,
              }
              await createRemovableItem({
                variables: {
                  args: data,
                }
              });
            }
          }}
          product_id={product_id}
          onSubmit={async()  => {
            await refetch();
            navigate(`/orders/${order?.getOrderByUniqueAttribute?.id}/edit/`);
          }}
        />
      )
    default:
      return "undefined";
  }
};

export default NewItemComponent;
