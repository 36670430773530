import React from "react";
import {
  useState,
} from "react";
import {
  Space,
  Button,
  Select,
} from "@mantine/core";

import {
  STYLE
} from "../../types/constants";

const SendOrderToSupplierModal = (
  props: {
    suppliers: { id: string, name: string, displayPriority: number | null }[],
    onSubmit: (selectedSupplierId: string) => void,
    plural?: number
  }
) => {
  const [selectedSupplierId, setSelectedSupplierId] = useState<string>("");

  return (
    <>
      <Select
        data-testid="select-provider"
        label={props.plural ? "Which supplier would you like to send these "+props.plural+" cases to ?" : "Which supplier would you like to send the case to ?"}
        placeholder="Please select a supplier"
        data={
          (props?.suppliers ?? [])
            .sort((a, b) => (a.displayPriority ?? Infinity) - (b.displayPriority ?? Infinity))
            .map((supplier) => ({ value: supplier.id, label: supplier.name }))
        }
        onChange={(e) => setSelectedSupplierId(e)}
      />
      <Space h="md" />
      <div style={{ textAlign: "center" }}>
        <Button
          data-testid="submit-provider-button"
          onClick={() => props.onSubmit(selectedSupplierId)}
          style={{ backgroundColor: STYLE.primary }}
        >
          Submit
        </Button>
      </div>
    </>
  );
};

export default SendOrderToSupplierModal;
