import { ItemType, TeethShadeSide } from "@jasper/shared";

const ImplantItemComponent = ({ item, index, onDelete, onUpdate, onUpdateTeeth }) => {
  return (
    <>
      <li>
        <span style={{ fontWeight: 'bold' }}>
          Item {index + 1}
        </span>
        &nbsp;
        <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => onDelete()}>
          (delete item)
        </span>
        {(
          item.itemType !== ItemType.IMPLANT
          && item.itemType !== ItemType.IMPLANT_CROWN
          && item.itemType !== ItemType.SPLINTED_CROWN_IMPLANT
          && item.itemType !== ItemType.BRIDGE_PONTIC
          && item.itemType !== ItemType.BRIDGE_IMPLANT
        ) &&
          <span style={{ textDecoration: 'underline', cursor: 'pointer' }} onClick={() => onUpdate()}>
            (update item)
          </span>
        }
      </li>
      <div style={{ marginLeft: '3rem' }}>
        <li>
          <span style={{ fontWeight: 'bold' }}>
            ItemType:
          </span>
          &nbsp;{item.itemType}
        </li>
        <li>
          <span style={{ fontWeight: 'bold' }}>
            Teeth:
          </span>
          &nbsp;{item?.teeth?.join(", ")}
        </li>
        {item?.itemMaterial?.label &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              ItemMaterial:
            </span>
            &nbsp;{item?.itemMaterial?.label}
          </li>
        }
        {item?.itemShade?.label &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              ItemShade:
            </span>
            &nbsp;{item?.itemShade?.label}
          </li>
        }
        {(item?.multiShadeInfo ?? []).length > 0 &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              Gradient Shade:
            </span>
            <div style={{ marginLeft: "3rem" }}>
              <li>
                <span style={{ fontWeight: 'bold' }}>Gingival:</span>
                &nbsp;{(item?.multiShadeInfo ?? []).find((shade: { teethShadeSide: TeethShadeSide }) => shade.teethShadeSide === TeethShadeSide.GINGIVAL)?.itemShade?.label}
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}>Base:</span>
                &nbsp;{(item?.multiShadeInfo ?? []).find((shade: { teethShadeSide: TeethShadeSide }) => shade.teethShadeSide === TeethShadeSide.BASE)?.itemShade?.label}
              </li>
              <li>
                <span style={{ fontWeight: 'bold' }}>Incisal:</span>
                &nbsp;{(item?.multiShadeInfo ?? []).find((shade: { teethShadeSide: TeethShadeSide }) => shade.teethShadeSide === TeethShadeSide.INCISAL)?.itemShade?.label}
              </li>
            </div>
          </li>
        }
        {item?.abutmentMaterial?.label &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              AbutmentMaterial:
            </span>
            &nbsp;{item?.abutmentMaterial?.label}
          </li>
        }
        {item?.abutmentType &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              AbutmentType:
            </span>
            &nbsp;{item?.abutmentType}
          </li>
        }
        {item?.implantPlatform &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              ImplantPlatform:
            </span>
            &nbsp;{item?.implantPlatform}
          </li>
        }
        {item?.implantReference &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              ImplantReference:
            </span>
            &nbsp;{item?.implantReference?.brand}
          </li>
        }
        {item?.retentionType &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              RetentionType:
            </span>
            &nbsp;{item?.retentionType}
          </li>
        }
        {item?.scanBodySuggestion &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              ScanBody suggestion:
            </span>
            &nbsp;{item?.scanBodySuggestion}
          </li>
        }
        {item?.standardAbutmentSuggestion &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              Standard abutment suggestion:
            </span>
            &nbsp;{item?.standardAbutmentSuggestion}
          </li>
        }
        {item?.implantReferenceSuggestion &&
          <li>
            <span style={{ fontWeight: 'bold' }}>
              Implant reference suggestion:
            </span>
            &nbsp;{item?.implantReferenceSuggestion}
          </li>
        }
      </div>
    </>
  );
};

export default ImplantItemComponent;
