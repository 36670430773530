import React, { useState } from "react";
import {
  Title,
  Space,
  Box,
  Text,
} from "@mantine/core";
import {
  SelectorButtonRef,
  YES_OR_NO_CHOICE,
  DentalArchesSvg,
} from "@jasper/shared";

const PartialDentureInformations = ({
  rhf,
  selectedTeeth,
  setSelectedTeeth,
}) => {
  const watchIsReplacement = rhf.watch("isReplacement");

  return (
    <>
      <Title order={2}>Information sur votre prothèse amovible partielle</Title>
      <Space h="md" />
      <SelectorButtonRef
        label="S'agit-il d'un remplacement de prothèse ?"
        name="isReplacement"
        data={YES_OR_NO_CHOICE}
        onSubmit={(selectedValue) => rhf.setValue("isReplacement", selectedValue)}
        control={rhf.control}
        errors={rhf.formState.errors}
        watch={watchIsReplacement}
        required={true}
      />
      <Text
        fw={490}
        size="sm"
      >
        Sélectionnez les dents à inclure dans la PAP
      </Text>
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
        }}
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <DentalArchesSvg
          teeth={selectedTeeth}
          width={275}
          height={366}
          onClick={(tooth) => {
            const index = selectedTeeth.indexOf(tooth);
            if (index >= 0){
              setSelectedTeeth(selectedTeeth.filter((t: number) => t !== tooth));
              rhf.setValue("teeth", selectedTeeth.filter((t: number) => t !== tooth));
              return;
            }
            setSelectedTeeth([...selectedTeeth, tooth])
            rhf.setValue("teeth", [...selectedTeeth, tooth]);
          }}
        />
      </Box>
    </>
  );
};

export default PartialDentureInformations;
