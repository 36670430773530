import React, { useState } from "react";
import {
  Stepper,
  Button,
  Group,
} from "@mantine/core";
import {
  useForm,
} from "react-hook-form";
import {
  useLocation,
} from "react-router-dom";

import {
  STYLE,
} from "../../../../types/constants";
import {
  ItemType,
} from "../../../../types/enums";
import {
  deduceShadeType,
} from '../../../../utils/item.utils';
import PartialDentureInformations from "./PartialDentureinformations";
import PartialDentureProthesis from "./PartialDentureProthesis";
import PartialDentureHooks from "./PartialDentureHooks";
import PartialDentureFlux from "./PartialDentureFlux";

const PartialDentureWorkflow = ({
  materialChoices,
  gingivaShadeChoices,
  order,
  accountProductPreferences,
  anatomyItemChoicesData,
  updateManyRemovableItem,
  updateOrder,
  onSubmit,
  product_id,
}) => {
  const location = useLocation();
  const savedItem = (location?.state?.product?.removableItem ?? []).find((item) => item.itemType === ItemType.PARTIAL_DENTURE); 
  const defaultTeeth = (location?.state?.product?.removableItem ?? []).reduce((acc, product) => {
    return ([...acc, ...product?.teeth]);
  }, []);
  const [selectedTeeth, setSelectedTeeth] = useState<number[]>(defaultTeeth ?? []);

  const [active, setActive] = useState(0);

  const nextStep = () => setActive((current) => (current < 3 ? current + 1 : current));
  const prevStep = () => setActive((current) => (current > 0 ? current - 1 : current));

  const rhfStepInformation = useForm({
    defaultValues: {
      isReplacement: savedItem?.isReplacement,
      teeth: defaultTeeth,
    }
  });

  const rhfStepProsthesis = useForm({
    defaultValues: {
      material: savedItem?.itemMaterial?.id,
      shadeType: deduceShadeType(savedItem?.itemShade?.id, order?.user?.id, accountProductPreferences),
      shade: savedItem?.teethShade?.id,
      gingivaShade: savedItem?.gingivaShade?.id,
    },
  });

  const rhfStepHooks = useForm({
    defaultValues: {
      hookComment: savedItem?.dentistNotes,
    }
  });

  const rhfStepFlux = useForm({
    defaultValues: {
      workflowType: savedItem?.workflowType,
    }
  });

  return (
    <>
      <Stepper active={active} onStepClick={setActive}>
        <Stepper.Step label="Informations">
          <PartialDentureInformations
            rhf={rhfStepInformation}
            selectedTeeth={selectedTeeth}
            setSelectedTeeth={(teeth: number[]) => setSelectedTeeth(teeth)}
          />
        </Stepper.Step>
        <Stepper.Step label="Prothèse">
          <PartialDentureProthesis
            materialChoices={materialChoices}
            gingivaShadeChoices={gingivaShadeChoices}
            order={order}
            accountProductPreferences={accountProductPreferences}
            anatomyItemChoicesData={anatomyItemChoicesData}
            rhf={rhfStepProsthesis}
          />
        </Stepper.Step>
        <Stepper.Step label="Crochets">
          <PartialDentureHooks
            defaultComment={savedItem?.dentistNotes}
            rhf={rhfStepHooks}
          />
        </Stepper.Step>
        <Stepper.Step label="Flux de travail">
          <PartialDentureFlux
            rhf={rhfStepFlux}
          />
        </Stepper.Step>
      </Stepper>

      <Group justify="center" mt="xl">
        <Button variant="default" onClick={prevStep}>Back</Button>
        <Button
          onClick={() => {
            switch (active){
              case 0:
                rhfStepInformation.handleSubmit(async(values) => {
                  await updateOrder(
                    {
                      product: {
                        connect: {
                          id: product_id,
                        }
                      },
                      itemType: ItemType.PARTIAL_DENTURE,
                      isReplacement: values.isReplacement
                    },
                    selectedTeeth,
                  )
                  nextStep();
                })();
                break;
              case 1:
                rhfStepProsthesis.handleSubmit(async(values) => {
                  await updateManyRemovableItem(
                    {
                      productId: product_id,
                      itemMaterialId: values.material,
                      gingivaShadeId: values.gingivaShade,
                      teethShadeId: values.shade,
                    },
                  )
                  nextStep();
                })();
                break;
              case 2:
                rhfStepHooks.handleSubmit(async(values) => {
                  await updateManyRemovableItem(
                    {
                      productId: product_id,
                      dentistNotes: values.hookComment,
                    },
                  )
                  nextStep();
                })();
                break;
              case 3:
                rhfStepFlux.handleSubmit(async(values) => {
                  await updateManyRemovableItem(
                    {
                      productId: product_id,
                      workflowType: values.workflowType,
                    },
                  )
                  onSubmit();
                })();
                break;
            }
          }}
          style={{ backgroundColor: STYLE.primary }} >Next step</Button>
      </Group>
    </>
  )
};

export default PartialDentureWorkflow;
