import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyQuery, useMutation, useQuery } from '@apollo/client';

import { 
  GET_ORDER_BY_UNIQUE_ATTRIBUTE,
  GET_ALL_MESSAGES_OF_ORDER,
  SEND_MESSAGE,
  MARK_NOTIFICATIONS_AS_READ,
  MARK_CHAT_MESSAGES_AS_READ,
  MARK_HELP_MESSAGES_AS_READ
} from '/@/gql';
import { FileType, OrderViewPage, useFiles, UserRole, getValideFiles, UserType } from '@jasper/shared';
import { UseAuth } from '/@/contexts';
import { useEffect, useState } from 'react';
import { socket } from '../../socket'
import { GET_FILES_DOWNLOAD_URLS } from '@jasper/shared/gql/files';
import { MARK_FILE_AS_VIEWED } from '../../gql/file';
import { Message, Order } from '@jasper/shared/types/interfaces';
import { notifications } from '@mantine/notifications';
const __TAKE__ = 5;

const OrderView = () => {

  const { order_id } = useParams();
  const { user } = UseAuth();
  const navigate = useNavigate();

  if(!order_id) {
    return
  }

  const { data, refetch } = useQuery(
    GET_ORDER_BY_UNIQUE_ATTRIBUTE,
    {
      variables: {
        where: {
          id:  order_id
        }
      },
      fetchPolicy: 'cache-first',
    }
  );

  const {getFileUrl, uploadFileToS3} = useFiles(order_id);

  const [message, setMessage] = useState('');
  const [scrollBottom, setScrollBottom] = useState(true);
  const [filesToSend, setFilesToSend] = useState<File[]>([]);
  const [page, setPage] = useState(0);
  const [messagesToShow, setMessageToShow] = useState<any[]>([])
  const [newMessage, setNewMessage] = useState<any>()
  const [hasMore, setHasMore] = useState<boolean>(false)
  const [pictureToMarkAsRead, setPictureToMarkAsRead] = useState<string | null>(null)
  const [providerGroupId, setProviderGroupId] = useState<string | null>(null)

  const getFilesWithPath = async(messages: Message[]) => {
    return await Promise.all(
      messages.map(async (msg) => {
        const updatedFiles = await Promise.all(
          (msg.files ?? []).map(async (file) => {
            try {
              return {
                id: file.id,
                path: await getFileUrl(file.key),
                pathCompressed: await getFileUrl(file.keyCompressed ?? file.key),
                key: file.key,
                keyCompressed: file.keyCompressed,
                fileType: file.fileType,
                read: file.read,
              };
            } catch (error) {
              console.error(`Error fetching path for file key: ${file.key}`, error);
              return { key: file.key, fileType: file.fileType, path: null };
            }
          })
        );
        return { ...msg, files: updatedFiles };
      })
    );
  };
  
  const { data: dataChats, loading } = useQuery(
    GET_ALL_MESSAGES_OF_ORDER,
    {
      variables: {
        take: __TAKE__,
        skip: __TAKE__ * page,
        where: {
          orderId: {
            equals: order_id,
          },
        },
      },
      fetchPolicy: 'cache-and-network',
      onCompleted: (data) => {
        if (data?.getAllChatsOfOrder?.count) {
          const newMessages = data?.getAllChatsOfOrder.notification ?? []; 
          getFilesWithPath(newMessages).then((processedMessages) => {
            if (processedMessages.length === newMessages.length) {
              setMessageToShow((prev) => [...(processedMessages.toReversed()), ...prev]);
              setScrollBottom(false)
      
              if ([...(processedMessages), ...messagesToShow].length === data?.getAllChatsOfOrder.count) {
                setHasMore(false);
              }
              else {
                setHasMore(true);
              }
            }
          });
        } else {                  
          setHasMore(false);
        }
      }
    }
  );

  const fetchMoreMessages = () => {
    if (hasMore) {
      setPage(page + 1)
    }
  };
  const [send] = useMutation(SEND_MESSAGE);
  const [updateMessages] = useMutation(MARK_NOTIFICATIONS_AS_READ);
  const [markFileAsRead] = useMutation(MARK_FILE_AS_VIEWED);
  const [markHelpNotificationsAsRead] = useMutation(MARK_HELP_MESSAGES_AS_READ);
  const [markChatMessagesAsRead] = useMutation(MARK_CHAT_MESSAGES_AS_READ);
  const setChatMessagesAsRead = async(providerGroupId: string) => {
    await markChatMessagesAsRead(
      {
        variables: {
          supplier: providerGroupId,
          data: {
            read: {
              set: true
            }
          },
          where: {
            orderId: {
              equals: order_id
            },
            userGroupId: {
              not: {
                equals: user?.userGroupId
              }
            }
          },
        },
      }
    )
  
  }

  const setChatOrderAsRead = async(providerGroupId: string) => {
    await updateMessages(
      {
        variables: {
          supplier: providerGroupId,
          data: {
            read: {
              set: true
            }
          },
          where: {
            orderId: {
              equals: order_id
            },
            userGroupId: {
              not: {
                equals: user?.userGroupId
              }
            }
          },
        },
      }
    )
  }
  const setOrderCommentsAsRead = async(providerGroupId: string) => {
    await markHelpNotificationsAsRead(
      {
        variables: {
          data: {
            read: {
              set: true
            }
          },
          where: {
            orderId: {
              equals: order_id
            },
            userGroupId: {
              not: {
                equals: user?.userGroupId
              }
            }
          },
          supplier: providerGroupId,
        },
      }
    )
  }

  const sendMessage = async () => {
    if (!data?.getOrderByUniqueAttribute?.provider?.id){
      notifications.show({
        title: "You must assign a provider first before sending a message",
        color: "red",
        message: "",
      });
      return;
    }
    if(filesToSend.length !== 0) {
      uploadFileToS3(filesToSend, FileType.CHAT_FILE).then(async(dataUpload) => {
        if(data && data.length !== 0 ) {
          await send(
            {
              variables: {
                args: {
                  createdAt: new Date(),
                  order: {
                    connect: {
                      id: order_id
                    }
                  },
                  updatedAt: new Date(),
                  userGroup: {
                    connect: {
                      id: user.userGroupId
                    }
                  },
                  message: message,
                  files:{
                    create: dataUpload
                  }
                },
                olderChatRead: (messagesToShow.filter((msg: Message) => msg.read === false && msg.userGroup.id === user.userGroupId).length === 0),
                supplier: data?.getOrderByUniqueAttribute?.provider?.id,
              }
            }
          )
        }
        setFilesToSend([])
      })
    }
    else {
      await send(
        {
          variables: {
            args: {
              createdAt: new Date(),
              order: {
                connect: {
                  id: order_id
                }
              },
              updatedAt: new Date(),
              userGroup: {
                connect: {
                  id: user.userGroupId
                }
              },
              message: message
            },
            olderChatRead: (messagesToShow.filter((msg: Message) => msg.read === false && msg.userGroup.id === user.userGroupId).length === 0),
            supplier: data?.getOrderByUniqueAttribute?.provider?.id,
          }
        }
      )
    }
  }

  const markFileAsReadHandler = () => {
    markFileAsRead(
      {
        variables: {
          id: pictureToMarkAsRead
        }
      }
    ).then(() => {
      refetch()
    })
  }

  const [orderComments, setOrderComments] = useState<any[]>([]);

  const [fileWithPath, setFileWithPath] = useState([]);
  const [getFilesDownloadUrls] = useLazyQuery(GET_FILES_DOWNLOAD_URLS, {
    onCompleted: (value) => {
      setFileWithPath(value?.getSignedDownloadUrlsFromKeys?.files || []);
    },
  });

  useEffect(() => {
    if(data?.getOrderByUniqueAttribute?.provider?.id && data.getOrderByUniqueAttribute.provider.id !== providerGroupId) {
      setProviderGroupId(data?.getOrderByUniqueAttribute.provider.id)
    }
    if(data?.getOrderByUniqueAttribute) {
      getFilesWithPath(data
        .getOrderByUniqueAttribute
        .orderComment
      ).then((orderCommentsWithUpdatedFilesWithFilePath) => {
        setOrderComments(orderCommentsWithUpdatedFilesWithFilePath);
      })
    }
    if (data && user.role === UserRole.USER && data?.getOrderByUniqueAttribute?.provider?.id !== user.userGroupId) {
      navigate('/chats?page=0')
    }
    if (data?.getOrderByUniqueAttribute?.files.length > 0) {
      const validFiles = getValideFiles(data?.getOrderByUniqueAttribute?.files ?? []);
      getFilesDownloadUrls({ variables: { args: {files :[ ...validFiles ]} } });
    }
    if(
      data?.getOrderByUniqueAttribute !== undefined
      && dataChats
          ?.getAllChatsOfOrder
          ?.notification
          ?.filter(
            (msg: Message) => msg.read === false && msg.userGroup.id !== user.userGroupId
          ).length > 0
    ) {
      setChatMessagesAsRead(data?.getOrderByUniqueAttribute.provider?.id)
    }
  }, [data?.getOrderByUniqueAttribute]);

  useEffect(() => {
    socket.emit('joinRoom', {room: 'chat-'+order_id});
    
    return () => {      
      socket.emit('leaveRoom', {room: 'chat-'+order_id})
      socket.off('chat')
    }
  }, [])

  useEffect(() => {
    if (providerGroupId !== null && providerGroupId) {
      if(data?.getOrderByUniqueAttribute?.ChatMarkAsUnread?.length > 0) {
        setChatOrderAsRead(providerGroupId)
      }
      if (user.type === UserType.SUPPLIER) {
        socket.on("assignNewProvider", (args: { order: Order }) => {
          if (args.order?.providerGroupId !== user.userGroupId && args.order?.id === order_id) {
            navigate('/chats?page=0');
          }
        });
        socket.on('setAdminMsgInProviderViewAsRead', () => {
          setMessageToShow((prevMessages: Message[]) => {
            return prevMessages.map((msg: Message) => ({ ...msg, read: true }));
          });
        })
      }
      else {
        socket.on('setProviderMsgInAdminViewAsRead', () => {
          setMessageToShow((prevMessages: Message[]) => {
            return prevMessages.map((msg: Message) => ({ ...msg, read: true }));
          });
        })
      }
  
      socket.on('chat', (newMessage) => {
        if(newMessage.userGroupId !== user.userGroupId) {
          setChatMessagesAsRead(providerGroupId)
          setNewMessage({...newMessage, read : true})
        }
        if(newMessage.files.length > 0) {
          getFilesWithPath([newMessage]).then((msg)=> {
            setNewMessage({...msg[0], read: true})
            setScrollBottom(true)
          })
        }
        else {
          newMessage.files = []
          setNewMessage(newMessage)
          setScrollBottom(true)
        }
      })
    }
    return () => {
      socket.off('assignNewProvider')
      socket.off('setAdminMsgInProviderViewAsRead')
      socket.off('setProviderMsgInAdminViewAsRead')
    }
  }, [providerGroupId])

  useEffect(()=>{
    if(newMessage) {
      setMessageToShow([...messagesToShow, newMessage])
    }
  }, [newMessage])


  useEffect(() => {
    if(pictureToMarkAsRead !== null) {
      markFileAsReadHandler()
    }
  },[pictureToMarkAsRead])

  return (
    <OrderViewPage
      orderComments={orderComments}
      fileWithPath={fileWithPath ?? []}
      order={data?.getOrderByUniqueAttribute}
      user={user}
      refetch={refetch}
      setMessage={setMessage}
      messages={messagesToShow}
      sendMessage={sendMessage}
      filesToSend={filesToSend}
      setFilesToSend={setFilesToSend}
      loading={loading}
      hasMore={hasMore} 
      fetchMoreMessages={fetchMoreMessages}
      scrollBottom={scrollBottom}
      refetchFiles={getFilesDownloadUrls}
      setPictureToMarkAsRead={setPictureToMarkAsRead}
    />
  )
};


export default OrderView;
