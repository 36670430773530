import {
  UserType,
  UserRole,
} from "../types/enums";
import { User } from "../types/interfaces";

export const getDentistCode = (dentist: User) => {
  if (!dentist?.firstName || !dentist?.lastName){
    return ("Doctor not specified");
  }
  return (`Dr${dentist?.firstName}${dentist?.lastName?.slice(0, 1)}`);
};

export const isUserProvider = (user: User): boolean => {
  return (user.type === UserType.SUPPLIER)
};

export const isUserStaff = (user: User): boolean => {
  return (user.type === UserType.JASPER);
};

export const isUserAdmin = (user: User): boolean => {
  return (user.type === UserType.JASPER && user.role === UserRole.ADMIN);
};

export const isUserStaffNotAdmin = (user: User): boolean => {
  return (user.type === UserType.JASPER && user.role === UserRole.STAFF);
};

export const getDomain = (env: string): string => {
  switch (env) {
    case "staging":
      return ".jasperdental.fr";
    case "production":
      return ".jasperdental.fr";
    default:
      return "localhost";
  }
}
