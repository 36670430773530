import React from 'react';
import { useForm } from "react-hook-form";
import {
  Button,
} from "@mantine/core";
import {
  useMutation,
} from "@apollo/client"
import {
  useLocation,
} from "react-router-dom";

import SelectorButtonRef from "../SelectorButtonRef";
import SelectInputRef from "../SelectInputRef";
import ShadeSelection from "../shadeSelection";
import {
  STYLE,
  YES_OR_NO_CHOICE,
} from "../../types/constants";
import {
  deduceShadeType,
  getItemCrownOrSplintedCrownFromProduct,
} from '../../utils/item.utils';
import {
  ItemType,
  TeethShadeSide,
  TeethShadeType,
} from "../../types/enums";
import { getAllPossibleSplintedCrown } from '../../utils/product.utils';
import { AnatomyItem } from '@prisma/client';

const CreateNewCrown = ({
  materialFormChoices,
  accountProductPreferences,
  order,
  anatomyItemChoicesData,
  onSubmit,
  itemType,
}) => {
  const location = useLocation();

  const savedItem = (itemType === ItemType.VENEER)
    ? (location?.state?.product?.anatomyItem ?? []).find((item: AnatomyItem) => item.itemType === ItemType.VENEER)
    : getItemCrownOrSplintedCrownFromProduct(location?.state?.product?.anatomyItem);


  const rhf = useForm({
    defaultValues: {
      material: savedItem?.itemMaterial?.id,
      shade: savedItem?.itemShade?.id,
      shadeType: deduceShadeType(savedItem?.itemShade?.id, order?.user?.id, accountProductPreferences),
      isMultiShade: savedItem?.teethshadeType === TeethShadeType.MULTI_SHADE,
      gingivalShade:
        (savedItem?.multiShadeInfo ?? []).find(
          (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.GINGIVAL,
        )?.itemShade?.id ?? undefined,
      baseShade:
        (savedItem?.multiShadeInfo ?? []).find(
          (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.BASE,
        )?.itemShade?.id ?? undefined,
      incisalShade:
        (savedItem?.multiShadeInfo ?? []).find(
          (multiShadeInfo: { teethShadeSide: TeethShadeSide }) =>
            multiShadeInfo.teethShadeSide === TeethShadeSide.INCISAL,
        )?.itemShade?.id ?? undefined,
      isSplintedCrown: ((location?.state?.product?.anatomyItem ?? []).filter((item) => item.itemType === ItemType.SPLINTED_CROWN)).length > 0,
    }
  });

  const watchShadeType = rhf.watch("shadeType");
  const watchMaterial = rhf.watch("material");
  const watchIsSplintedCrown = rhf.watch("isSplintedCrown");

  const submit = (data) => {
    onSubmit(data, data.isMultiShade, savedItem);
  };

  return (
    <>
      <SelectInputRef
        label="Matériau de la couronne"
        name="material"
        data={materialFormChoices}
        control={rhf.control}
        errors={rhf.formState.errors}
        required={true}
      />
      {getAllPossibleSplintedCrown(location?.state?.product?.teeth ?? []).length > 0 &&
        <SelectorButtonRef
          label="Faut-il solidariser les couronnes ?"
          name="isSplintedCrown"
          data={YES_OR_NO_CHOICE}
          onSubmit={(selectedValue) => rhf.setValue("isSplintedCrown", selectedValue)}
          control={rhf.control}
          errors={rhf.formState.errors}
          watch={watchIsSplintedCrown}
          required={true}
        />
      }
      {watchMaterial !== "METAL_NON_PRECIOUS" &&
        <ShadeSelection
          watchShadeType={watchShadeType}
          user={order?.user}
          control={rhf.control}
          errors={rhf.formState.errors}
          anatomyItemChoicesData={anatomyItemChoicesData}
          onSelect={(selectedValue) => rhf.setValue("shadeType", selectedValue)}
          order={order}
          accountProductPreferences={accountProductPreferences}
          rhf={rhf}
          itemType={ItemType.CROWN}
        />
      }
      <div style={{ textAlign: "center" }}>
        <Button style={{ backgroundColor: STYLE.primary }} onClick={rhf.handleSubmit(submit)}>
          Save
        </Button>
      </div>
    </>
  );
};

export default CreateNewCrown;
