import {
  useParams,
  useNavigate,
} from "react-router-dom";
import {
  useQuery,
} from "@apollo/client";
import {
  Box,
  Title,
  Button,
} from "@mantine/core";

import {
  GET_USER,
  STYLE,
} from "@jasper/shared";

const UserView = () => {
  
  const { user_id } = useParams();

  const navigate = useNavigate();

  const { data } = useQuery(
    GET_USER,
    {
      variables: {
        where: {
          id: user_id
        }
      },
      fetchPolicy: "network-only",
    }
  );

  return (
    <>
      <div style={{display:'grid', gridTemplateColumns: 'repeat(2, 1fr)', width:'100%', gap: '20px'}}>
        <Box span={5} style={{ border: "0.01rem solid lightgrey", padding: "3rem", margin: "1rem" }}>
          <Title style={{ textAlign: "center", marginBottom: "2rem" }} fw={200}>General informations</Title>
          <div>
            <span style={{ fontWeight: "bold" }}>Firstname:</span> { data?.user?.firstName }<br/>
            <span style={{ fontWeight: "bold" }}>Lastname:</span> { data?.user?.lastName }<br/>
            <span style={{ fontWeight: "bold" }}>Email:</span> { data?.user?.email }<br/>
            <span style={{ fontWeight: "bold" }}>Role:</span> { data?.user?.role }<br/>
            <span style={{ fontWeight: "bold" }}>Type:</span> { data?.user?.type }
          </div>
        </Box>
        {(data?.user?.userGroup?.addresses ?? []).length > 0 &&
          <Box style={{ border: "0.01rem solid lightgrey", padding: "3rem", margin: "1rem" }}>
            <Title style={{ textAlign: "center", marginBottom: "2rem" }} fw={200}>Address</Title>
            { data?.user?.userGroup?.addresses[0].streetNumber }, { data?.user?.userGroup?.addresses[0].streetName }<br/>
            { data?.user?.userGroup?.addresses[0].postcode }, { data?.user?.userGroup?.addresses[0].city }
          </Box>
        }
        <Box style={{ border: "0.01rem solid lightgrey", padding: "3rem", margin: "1rem" }}>
          <Title style={{ textAlign: "center", marginBottom: "2rem" }} fw={200}>Clinic</Title>
          <div>
            <span style={{ fontWeight: "bold" }}>Group:</span> <span style={{ textDecoration: "underline", cursor: "pointer" }} onClick={() => navigate(`/groups/${data?.user?.userGroup?.id}`)}>{ data?.user?.userGroup?.name }</span><br/>
            <span style={{ fontWeight: "bold" }}>Clinic number:</span> { data?.user?.userGroup?.clinicNumber }<br/>
            <span style={{ fontWeight: "bold" }}>Master account:</span> { data?.user?.isManager ? "YES" : "NO" }     
          </div>
        </Box>
        <Box style={{ border: "0.01rem solid lightgrey", padding: "3rem", margin: "1rem" }}>
          <Title style={{ textAlign: "center", marginBottom: "2rem" }} fw={200}>Billing</Title>
          <div>
            <span style={{ fontWeight: "bold" }}>Pricing type:</span> { data?.user?.clientBilling?.pricingType }<br/>
          </div>
        </Box>
      </div>
      <div style={{ textAlign: "center" }}>
        <Button
          style={{ backgroundColor: STYLE.primary }}
          onClick={() => navigate(`/users/${user_id}/edit`, { state: { user: data.user } })}
        >
          Edit user
        </Button>
      </div>
    </>
  );
};

export default UserView;
