import { gql } from '@apollo/client';

export const GET_ALL_GROUPS = gql`
  query GetAllGroups ($where: UserGroupWhereInput, $take: Int, $skip: Int, $orderBy: [UserGroupOrderByWithRelationInput!]) {
    getAllGroups (where: $where, take: $take, skip: $skip, orderBy: $orderBy) {
      id
      name
    }
  }
`;

export const GET_GROUP_BY_ID = gql`
  query GetUserGroupsWhereUnique($where: UserGroupWhereUniqueInput!) {
    getUserGroupsWhereUnique(where: $where) {
      id
      name
      groupType
      clinicNumber
      groupMembers {
        email
        firstName
        lastName
        id
        isManager
      }
      addresses {
        city
        name
        country
        id
        streetName
        streetNumber
        postcode
      }
    }
  }
`;

export const CREATE_GROUP = gql`
  mutation CreateUserGroup($args: UserGroupCreateInput!) {
    createUserGroup(args: $args) {
      id
    }
  }
`;

export const UPDATE_USER_GROUP = gql`
  mutation UpdateUserGroup($data: UserGroupUpdateInput!, $where: UserGroupWhereUniqueInput!) {
    updateUserGroup(data: $data, where: $where) {
      id
    }
  }
`;
